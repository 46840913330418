import React from "react";
const itemizeExpenseTable = props => {
  let tableBody = [];
  let expenseTypeId = [];
  let itemization_id = [];
  let expenseTypeName = [];
 
if(props.actionButtons.length>0 && (props.currentPageName === 'Approval')){
  for (const key in props.itemizationForm) {
    if (Array.isArray(props.itemizationForm[key].itemizationField)) {
      tableBody.push(props.itemizationForm[key].itemizationField);
      expenseTypeId.push(props.itemizationForm[key].expenseTypeId._id);
      itemization_id.push(props.itemizationForm[key].itemization_id);
      expenseTypeName.push(
        props.itemizationForm[key].expenseTypeId.expenseTypeName
      );
    }
  }
}else{
  for (const key in props.itemizationForm) {
    if (Array.isArray(props.itemizationForm[key].itemizationField)) {
      tableBody.push(props.itemizationForm[key].itemizationField);
      if(props.currentPageName === 'financeAdmin') expenseTypeId.push(props.itemizationForm[key].expenseTypeId._id);
      else expenseTypeId.push(props.itemizationForm[key].expenseTypeId.id);
      itemization_id.push(props.itemizationForm[key].itemization_id);
      expenseTypeName.push(
        props.itemizationForm[key].expenseTypeId.expenseTypeName
      );
    }
  }
}
  return (
    <div className="col-md-12">
    <div className="">
      {props.currentPageName !== "Approval" ?
      <div className="btn-group-margin p-3">
        <button
          disabled={props.submitStatus !== "Pending Submission"}
          onClick={
            props.onClickCreateItemisationModal
          }
          className="btn"
          data-toggle="modal"
          data-target="#itemisationExpenseModal"
        >
          Create Itemization
    </button>
      </div>:
       <div className="btn-group-margin p-3">
         </div>
      }
      {tableBody
        ? tableBody.map((result, index) => {
          return (
            <div key={index} className="white-card mb-0 report">
              <div className="grey-head">
                <h6 className="sub-heading pt-2 mt-1 float-left place__middle">
                  {expenseTypeName[index]}
                </h6>
                {(props.submitStatus === "Pending Submission" || props.submitStatus === "SendBack to Employee"  || (props.actionButtons.length>0 && props.currentPageName === 'Approval')||(props.currentPageName === 'financeAdmin')) &&
                  <div className="btn-group-margin float-right">
      {props.submitStatus !== "Verified" &&
                    <button
                    onClick={e =>
                      props.onItemisationEditClickHandler(expenseTypeId[index],itemization_id[index])
                    }
                    type="button"
                    className="btn"
                  >
                    EDIT
                  </button>
      }
                    {props.submitStatus === "Pending Submission" &&
                    <button
                      onClick={e =>
                        props.onItemisationSelectedDeleteClickHandler(
                          expenseTypeId[index],
                          itemization_id[index]
                        )
                      }
                      data-toggle="modal"
                      data-target="#deleteItemisation"
                      type="button"
                      className="btn"
                    >
                      Delete
                    </button>
                    }
                  </div>
                }
                <div className="clearfix" />
              </div>
              <div className="px-4 pt-4">
                <div>
                  <div className="row">
                    {result &&
                      result.map((detail, index) => {
                        let theadArray = [];
                        theadArray = result.map(header => header.columnName);
                        let AMOUNT = theadArray.indexOf("AMOUNT");
                        if (detail.activeStatus) {
                          if (
                            !(
                              detail.columnName === "CURRENCY" ||
                              detail.columnName === "AMOUNT" ||
                              detail.columnName === 'TRANSACTION_DATE'
                            )
                          ) {
                            return (
                              <div className="col-lg-3 mb-3" key={detail.id}>
                                {detail.columnName !== "PERSONAL_EXPENSE" ? (
                                  <div  className="border-b-1">
                                    <span className="fs-12">{detail.labelName}</span>
                                    <br />
                                    <h6 className="fs-14">
                                      {detail.fieldValue
                                        ? detail.fieldValue
                                        : "-"}
                                    </h6>
                                  </div>
                                ) : (
                                    <div  className="border-b-1">
                                      <span className="fs-12">{detail.labelName}</span>
                                      <br />
                                      <h6 className="fs-14">
                                        {detail.fieldValue === true
                                          ? "Yes"
                                          : "No"}
                                      </h6>
                                    </div>
                                  )}
                              </div>
                            );
                          } else if (detail.columnName === "AMOUNT") {
                            return (
                              <div className="col-lg-3 mb-3" key={index}>
                                <div  className="border-b-1">
                                  <span className="fs-12">{detail.labelName}</span>
                                  <br />
                                  <h6 className="fs-14">
                                    {props.expenseEntryCurrency}&nbsp;
                                    {(new Intl.NumberFormat('en-IN',{ minimumFractionDigits: 2,maximumFractionDigits:2 }).format(result ? result[AMOUNT].fieldValue:0))}
                                  </h6>
                                </div>
                              </div>
                            );
                          }
                          else if (detail.columnName === 'TRANSACTION_DATE') {
                            return (
                              <div className="col-lg-3" key={index}>
                                <div className="border-b-1">
                                  <span className="fs-12">{detail.labelName}</span>
                                  <br />
                                  <h6 className="fs-14">{detail.fieldValue ? detail.fieldValue : ""}</h6>
                                </div>
                              </div>
                            );
                          }
                        }

                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        })
        : null}
      </div>
                                  </div>
  );
};

export default itemizeExpenseTable;
