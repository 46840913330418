import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class PageNotFound extends Component { 
    render() {
        return (
            <div className="login-ext">
                <div className="logo">
                    <NavLink to="/"><img src="/assets/images/logo_login.svg" alt="Logo" /></NavLink>
                </div>
                <div className="d-table d-table__middle">
                    <div>
                        <form autoComplete="off">
                            <div className="center-content">
                                <h2>Under Development</h2>
                                <p><b>We are working on it!</b></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default PageNotFound; 