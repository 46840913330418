// @flow

import { createContext } from "react";
import { TripTypes } from "src/_constants";

/*::

export type TripType = string | number // | symbol

export type TripTypeBarContextType = {
  activeType: TripType,
  changeType: ?TripType => void
};
*/

export const TripTypeBarContext = createContext/*:: <TripTypeBarContextType> */(
  {
    activeType: TripTypes.TAXI,
    changeType: () => {}
  }
);
