import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import Loader from "../../../../common/Loader";
import { checkIsArray } from "../../../../../utils/util";
class AllocatedGraphModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Allocation",
            data: [],
            backgroundColor: [],
            borderWidth: 0
          }
        ]
      },
      allocationDetails: [],
      allocatedIn: "",
      departments: [],
      allocationAmounts: [],
      totalAllocationAmount: 0
    };
  }
  componentDidMount() {
    this.setState({ allocationDetails: this.props.allocationDetails });
    this.setGraphData(this.props.allocationDetails);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.allocationDetails !== this.state.allocationDetails) {
      this.setState({ allocationDetails: nextProps.allocationDetails });
      this.setGraphData(nextProps.allocationDetails);
    }
  }

  getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  setGraphData = allocationDetails => {
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let allocatedIn = "";
    let baseAmount = 0;
    if (allocationDetails && allocationDetails.length) {
      for (let index = 0; index < allocationDetails.length; index++) {
        labels.push(
          allocationDetails[index].allocationForm
            .map(
              formData => formData.fieldValue
            ))

        data.push(
          allocationDetails[index].amountOrPercentage.fieldName === "Percentage"
            ? +allocationDetails[index].amountOrPercentage.fieldValue
            : +allocationDetails[index].amountOrPercentage.fieldValue
        );
        backgroundColor.push(this.getRandomColor());
        allocatedIn =
          allocationDetails[index].amountOrPercentage.fieldName === "Percentage"
            ? "Percentage"
            : "Amount";
        baseAmount = allocationDetails[index].baseAmount;
      }
    }
    let mainLabel = []
    for (let i = 0; i < labels.length; i++) {
      mainLabel.push(
        labels[i].filter(item => {
          if (item !== "") {

            return item
          }
        }))
    }
    // let DepartLabels = labels.map(function(x) {
    //   return x[0];
    // });
    let DepartLabels1 = mainLabel.map(function (x) {
      return x[x.length - 1];
    });
    let chartData = { ...this.state.chartData };
    let dataset = { ...this.state.chartData?.datasets[0] };
    this.setState({
      totalAllocationAmount: baseAmount,
      allocatedIn: allocatedIn,
      departments: mainLabel,
      allocationAmounts: data,
      chartData: {
        ...chartData,
        labels: DepartLabels1,
        datasets: [
          {
            ...dataset,
            data: data,
            backgroundColor: backgroundColor
          }
        ]
      }
    });
  };

  render() {
    return (
      <div
        className="modal fade"
        id="allocatedGraphModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AllocatedGraphModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close d-none"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-head">
              <h6 className="modal-subtitle">Expense</h6>
              <h5 className="modal-title" id="uploadReceiptsLabel">
                Allocation Details
              </h5>
            </div>
            <div className="modal-middle pm-30">
              {!this.props.isGraphDataLoading ? (
                checkIsArray(this.state.allocationDetails) ? (
                  <div className="row">
                    <div className="col-md-6">
                      <Doughnut
                        data={this.state.chartData}
                        options={{
                          maintainAspectRatio: false
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="table-container">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Cost Allocation</th>
                                <th>Value ({this.state.allocatedIn})</th>
                              </tr>
                            </thead>
                            <tbody>
                              {checkIsArray(this.state.allocationAmounts) &&
                                this.state.allocationAmounts.map(
                                  (amount, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{this.state.departments[index].join(' > ')}</td>
                                        <td>{amount}</td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                            Total Allocated Amount :{" "}
                            {this.props.userBaseCurrency}{" "}
                            {new Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }).format(this.state.totalAllocationAmount)}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                    "Please allocate expense."
                  )
              ) : (
                  <Loader />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AllocatedGraphModal;
