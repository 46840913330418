import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ReportCard from "./components/reportCard";
import ReportFilter from "./components/reportFilter";
import ExpenseHeader from "./components/expenseHeader";
import { expenseReport } from "../../../_services/expenseReport";
import Pagination from "react-js-pagination";
import Loader from "src/_components/common/Loader";
import _ from 'lodash';

class ReportsPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      selected: "This Month",
      search: "",
      companyId: localStorage["440a28"],
      expenseReport: [],
      isLoading: false,
      pageLimit: 12,
      pageNumber: 1,
      totalCount: 0
    };
  }
  componentDidMount() {
    this.getAllExpenseReports();
    localStorage.removeItem('expenseEntryId');
    localStorage.removeItem('expenseType');
    localStorage.removeItem("expenseTypeName");
    localStorage.removeItem("itemSelectedExenseTypeName");
    localStorage.removeItem('extypeId');
    localStorage.removeItem('remainigItemise');
    localStorage.removeItem('currency');
  }
  getAllExpenseReports = async () => {
    this.setState({ isLoading: true });
    let pageNumber = this.state.pageNumber;
    let pageLimit = this.state.pageLimit;
    let companyId = this.state.companyId;
    let search = this.state.search;
    let response = await expenseReport.getAllExpenseReports(
      companyId,
      pageNumber,
      pageLimit,
      search
    );
    // console.log(response)
    if (response && response.count && response.count > 0) {
      this.setState({ isLoading: false });
      this.setState({
        expenseReport: response.result.headerArray,
        baseCurrency: response.result.baseCurrency,
        totalCount: response.count
      });
    } else {
      this.setState({ isLoading: false });
      this.setState({ expenseReport: [], baseCurrency: "", totalCount: 0 });
    }
  };
  onSubmitHandler = event => {
    event.preventDefault();
  };
  onSelectHandler = option => {
    this.setState({ selected: option });
  };
  onChangeHandler = event => {
    this.setState({ searchText: event.target.value });
  };
  onSearchHandler = _.debounce(async (searchText) => {
    {
      let search = searchText;
      this.setState({ search: searchText, isLoading: true, page: 1 });
      let companyId = this.state.companyId;
      let pageLimit = this.state.pageLimit;
      let pageNumber = 1;
      let response = await expenseReport.getAllExpenseReports(
        companyId,
        pageNumber,
        pageLimit,
        search
      );
      if (response) {
        this.setState({
          expenseReport: response.result.headerArray,
          baseCurrency: response.result.baseCurrency,
          totalCount: response.count,
          isLoading: false
        });
      } else {
        this.setState({ isLoading: false })
      }
    };
  }, 250);

  onClickNavHandler = (headerId, policyId, workFlowid, report) => {
    localStorage.setItem("headerId", headerId);
    localStorage.setItem("policyId", policyId);
    if (workFlowid !== '' && workFlowid !== undefined && workFlowid !== null) {
      localStorage.setItem("workFlowid", workFlowid);
      if (headerId !== "" && policyId !== "") {
        this.props.history.push(`./details/${workFlowid}`);
      }
    } else {
      if (headerId !== "" && policyId !== "") {
        this.props.history.push("./details");
      }
    }
  }

  onPageChangeHandler = async page => {
    this.setState({ pageNumber: page, isLoading: true });
    let companyId = this.state.companyId;
    let pageLimit = this.state.pageLimit;
    let search = this.state.search;
    let pageNumber = page;
    let response = await expenseReport.getAllExpenseReports(
      companyId,
      pageNumber,
      pageLimit,
      search
    );
    if (response) {
      this.setState({
        expenseReport: response.result.headerArray,
        baseCurrency: response.result.baseCurrency,
        totalCount: response.count,
        isLoading: false
      });
    } else {
      this.setState({ isLoading: false })
    }
  };

  render() {
    // console.log(this.state.expenseReport)
    const activeNav = this.props.location.pathname.split("/")[2];
    return (
      <div>
        <div className="p-padding">
          <ExpenseHeader activeNav={activeNav} />
          <div className="white-card">
            <div className="pad">
              <div className="table-container mx-0 less-pad">
                <ReportFilter
                  onSubmitHandler={this.onSubmitHandler}
                  onSelectHandler={this.onSelectHandler}
                  onChangeHandler={this.onChangeHandler}
                  selected={this.state.selected}
                  onSearchHandler={this.onSearchHandler}
                />
              </div>
              {this.state.isLoading === false ? (
                <ReportCard
                  expenseReport={this.state.expenseReport}
                  onClickNavHandler={this.onClickNavHandler}
                  entryObject={this.state.entryObject}
                />
              ) : (
                  <Loader></Loader>
                )}
              {this.state.totalCount <= 0 && this.state.isLoading === false &&
                <div className="text-center">
                  <img
                    width="200"
                    className="img-fluid"
                    alt="Fetching Reports"
                    src="/assets/images/no_reports.svg"
                  />
                  <h4>
                    <b>Oh!</b>
                  </h4>
                  <p className="mb-3">There are no reports to show.</p>
                  <NavLink to="/expense/reports" className="btn">
                    New Report
                    </NavLink>
                </div>
              }
            </div>
            <div className="table-container">
              <div className="table-footer px-0">
                <div className="row">
                  <div className="col-12">
                    {this.state.totalCount > 0 && (
                      <Pagination
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageLimit}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={5}
                        onChange={this.onPageChangeHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ReportsPage;
