import React, {  useRef } from "react";
import NewWindow from "react-new-window";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { getFullUserName, getUserBaseCurrency } from "src/utils/util";
import ExpenseItemisationPdf from "./expenseDetail/expenseItemisationPdf";
import ExpenseEntryPdf from "./expenseDetail/expenseEntryPdf";
import ExpenseSummaryPdf from "./expenseDetail/expenseSummaryPdf";
import ExpenseAllocationPdf from "./expenseDetail/expenseAllocationPdf";

export const ExpenseSettlementPdfPage = props => {
  const componentRef = useRef();
  let expenseReportId, reportName, userName, status, baseCurrency;
  expenseReportId = props.headerDetails.reportId;
  reportName = props.headerDetails.headerForm.find(
    forms => forms.columnName === "REPORT_NAME"
  ).fieldValue;
  userName = props.headerDetails.user
    ? getFullUserName(props.headerDetails.user.fieldData)
    : "-";
  status = props.headerDetails.status;
  baseCurrency = props.headerDetails.user
    ? getUserBaseCurrency(props.headerDetails.user.fieldData)
    : "-";
    let userDetailColumns = ["EMP_ID","ORG_UNIT_1","ORG_UNIT_2","ORG_UNIT_3","ORG_UNIT_4"
      ,"ORG_UNIT_5","ORG_UNIT_6"]
    let reportHeaderFields = ["REPORT_NAME", "REPORT_DATE", "ORG_UNIT_1", "ORG_UNIT_2", "ORG_UNIT_3", "ORG_UNIT_4", "ORG_UNIT_5", "ORG_UNIT_6"];
    let userDetails = props.headerDetails.user.fieldData.filter((fields)=>
    userDetailColumns.includes(fields.columnName)===true );
    let reportHeaderDetails =  props.headerDetails.headerForm.filter((fields)=>
    reportHeaderFields.includes(fields.columnName)===true );
  return (
    <NewWindow>
      <div
        id="root"
        style={{
          backgroundColor: "#fff",
          wordBreak: "break-all",
          margin: "0",
          padding: "0",
          fontSize: "12px",
          fontFamily: "Arial, Helvetica, sans-serif",
          letterSpacing: "0.3px"
        }}
      >
        <ReactToPrint
          trigger={() => (
            <button onClick={e => NewWindow.print()}>Print this out!</button>
          )}
          content={() => componentRef.current}
        />
        <div style={{ padding: " 20px" }}>
          <div style={{ float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                color: "#334681",
                fontWeight: "600"
              }}
            >
              ExpenseId
            </p>
            <p style={{ margin: "0px", color: "#a7a9c0" }}>
              {expenseReportId && expenseReportId}
            </p>
          </div>
          <div style={{ float: "right", paddingTop: "10px" }}>
            <img
              src="http://dev.curiotechnologies.in/assets/images/left-menu-logo.svg"
              style={{
                height: "40px",
                width: "auto"
              }}
              alt="logo"
            />
          </div>
          <div style={{ clear: "both" }} />
        </div>

        <div style={{ padding: "10px 20px", backgroundColor: "#F7F7F7" }}>
          <p style={{ margin: 0, fontWeight: "600" }}>REPORT DETAILS</p>
        </div>
        <div
          style={{ padding: "10px 20px", borderBottom: "1px solid #f1f1f2" }}
        >
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Expense Report ID
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {expenseReportId && expenseReportId}
            </p>
          </div>
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Travel Request ID
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
             -
            </p>
          </div>
         
          {reportHeaderDetails && reportHeaderDetails.length &&reportHeaderDetails.map((fields)=>
           <div style={{ width: "20%", float: "left" }}>
           <p
             style={{
               marginBottom: "8px",
               textTransform: "uppercase",
               color: "#a7a9c0",
               fontWeight: "400"
             }}
           >
             {fields.labelName}
           </p>
           <p
             style={{
               color: "#334681",
               marginTop: "0",
               fontWeight: "600",
               opacity: "0.5"
             }}
           >
             {fields.fieldValue?fields.fieldValue:'-'}
           </p>
         </div> 
          )
          }

          <div style={{ clear: "both" }} />
        </div>
        <div
          style={{ padding: "10px 20px", borderBottom: "1px solid #f1f1f2" }}
        >
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Employee Name
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {userName && userName}
            </p>
          </div>
          {userDetails && userDetails.length &&userDetails.map((fields)=>
           <div style={{ width: "20%", float: "left" }}>
           <p
             style={{
               marginBottom: "8px",
               textTransform: "uppercase",
               color: "#a7a9c0",
               fontWeight: "400"
             }}
           >
             {fields.label}
           </p>
           <p
             style={{
               color: "#334681",
               marginTop: "0",
               fontWeight: "600",
               opacity: "0.5"
             }}
           >
             {fields.fieldValue}
           </p>
         </div> 
          )
          }
          <div style={{ clear: "both" }} />
        </div>
        <div
          style={{ padding: "10px 20px", borderBottom: "1px solid #f1f1f2" }}
        >
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Status
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {status}
            </p>
          </div>

          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Report Total
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {baseCurrency && baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.totalAmount)}
            </p>
          </div>
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Due Employee
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {baseCurrency && baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.amountDueCompany)}
            </p>
          </div>
          <div style={{ width: "20%", float: "left" }}>
            <p
              style={{
                marginBottom: "8px",
                textTransform: "uppercase",
                color: "#a7a9c0",
                fontWeight: "400"
              }}
            >
              Due Company
            </p>
            <p
              style={{
                color: "#334681",
                marginTop: "0",
                fontWeight: "600",
                opacity: "0.5"
              }}
            >
              {baseCurrency && baseCurrency}{" "}
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(props.headerDetails.amountDueEmployee)}
            </p>
          </div>
          <div style={{ clear: "both" }} />
        </div>

        <div style={{ padding: "10px 20px", backgroundColor: "#F7F7F7" }}>
          <p style={{ margin: "0", fontWeight: "600" }}>SUMMERY OF EXPENSE </p>
        </div>

        <ExpenseSummaryPdf
            headerDetails={props.headerDetails}
            baseCurrency={baseCurrency}
            reportName={reportName}
         />

        <div style={{ padding: "10px 20px", backgroundColor: "#F7F7F7" }}>
          <p style={{ margin: 0, fontWeight: "600" }}>EXPENSE DETAIL</p>
        </div>
        {props.expenseEntryList.length &&
          props.expenseEntryList.map((entries ,index)=> {
            return (
           entries && entries.entryForm && entries.entryForm.length &&
            <>
                <p key={index} style={{ margin: "10px 20px", fontWeight: "600" }}>
                    {entries.expenseTypeObj.expenseTypeName}
                </p>
              <div key={entries.id} style={{ padding: "0 20px" }}>
                <ExpenseEntryPdf entryForm={entries.entryForm} />
                {entries.itemizationForm && Object.keys(entries.itemizationForm) && Object.keys(entries.itemizationForm).length && (
                  <div key={entries.id+index} style={{ padding: "10px 20px" }}>
                    <p
                      style={{
                        padding: "10px 0",
                        fontWeight: "600",
                        marginBottom: "0",
                        marginTop: "0"
                      }}
                    >
                      Itemization for Expense
                    </p>
                    <ExpenseItemisationPdf
                      keyIndex={index+entries.id}
                      itemizationForm={entries.itemizationForm}
                    />
                  </div>
                )}
              </div>
              </>
            );
          })}
        {/* <ExpenseAllocationPdf /> */}
      </div>
    </NewWindow>
  );
};
export default ExpenseSettlementPdfPage;
