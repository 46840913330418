import React, { Component } from "react";
import { object } from "@storybook/addon-knobs";
class reportHeaderTable extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps;
  }
  render() {
    const { navigationPaths } = this.props;
    return (
      <div className="col-lg-9">
        <div className="table-responsive">
          <div className="px-15 py-3">
            <div className="row">
              {navigationPaths &&
                navigationPaths.length > 0 &&
                navigationPaths.map((paths, index) => (
                  <React.Fragment key={index + "ssdd"}>
                    <div className="col-lg-4">
                      <div className="border-b-1">
                        <span className="fs-12">From</span>
                        <br />
                        <h6 className="fs-14">{`${paths.fromAddress}`}</h6>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="border-b-1">
                        <span className="fs-12">To</span>
                        <br />
                        <h6 className="fs-14">{paths.toAddress}</h6>
                      </div>
                    </div>{" "}
                    <div className="col-lg-4" key={index}>
                      <div className="border-b-1">
                        <span className="fs-12">Via</span>
                        <br />
                        <h6 className="fs-14">{`${paths.via && paths.via}`}</h6>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              {this.props.expenseEntryDetails &&
                this.props.expenseEntryDetails.map((detail, index) => {
                  if (detail.activeStatus) {
                    if (
                      !(
                        detail.columnName === "CURRENCY" ||
                        detail.columnName === "AMOUNT" ||
                        detail.columnName === "TRANSACTION_DATE"
                      )
                    ) {
                      return (
                        <div className="col-lg-4" key={detail.id}>
                          {detail.dataType !== "boolean" &&
                            detail.dataType !== "checkbox" ? (
                              <div className="border-b-1">
                                <span className="fs-12">{detail.labelName}</span>
                                <br />
                                <h6 className="fs-14">
                                  {(typeof detail.fieldValue !== "Object") ? (detail.fieldValue === "" ? detail.fieldItemName : detail.fieldValue) : (detail.fieldValue.itemName)}
                                </h6>
                              </div>
                            ) : (
                              <div className="border-b-1">
                                <span className="fs-12">{detail.labelName}</span>
                                <br />
                                <h6 className="fs-14">
                                  {typeof detail.fieldValue !== "object" &&
                                    detail.fieldValue === true
                                    ? "Yes"
                                    : "No"}
                                </h6>
                              </div>
                            )}
                        </div>
                      );
                    } else if (detail.columnName === "AMOUNT") {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="border-b-1">
                            <span className="fs-12">{detail.labelName}</span>
                            <br />
                            <h6 className="fs-14">
                              {this.props.expenseEntryCurrency}&nbsp;
                              {new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(
                                detail.fieldValue ? detail.fieldValue : 0
                              )}
                            </h6>
                          </div>
                        </div>
                      );
                    } else if (detail.columnName === "TRANSACTION_DATE") {
                      return (
                        <div className="col-lg-4" key={index}>
                          <div className="border-b-1">
                            <span className="fs-12">{detail.labelName}</span>
                            <br />
                            <h6 className="fs-14">
                              {detail.fieldValue ? detail.fieldValue : ""}
                            </h6>
                          </div>
                        </div>
                      );
                    }
                    // else if(detail.dataType === 'search'){
                    //   return (
                    //     <div className="col-lg-4" key={index}>
                    //       <div className="border-b-1">
                    //         <span className="fs-12">{detail.labelName}</span>
                    //         <br />
                    //         <h6 className="fs-14">{detail.searchName ? detail.searchName : ""}</h6>
                    //       </div>
                    //     </div>
                    //   );
                    // }
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reportHeaderTable;
