import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { userService } from '../../_services';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '../common/_forms';

class ResetPassword extends Component {
    constructor(props) {
        localStorage.clear();
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            companyCode: '',
            email: sessionStorage.getItem('resetDataEmail'),
            password: '',
            confirmPassword: ''
        };
    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    loading = false
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            const { email, password, confirmPassword } = this.state;
            if (email && password && confirmPassword) {
                if (password === confirmPassword) {
                    let payload = {
                        email: this.state.email,
                        password: this.state.password
                    }

                    userService.updatePassword(payload)
                        .then(data => {
                            toast.success(data.message)
                            this.props.history.push('/login');
                        })
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }
    render() {

        return (
            <div className="login-ext">
                <div className="logo">
                    <NavLink to="/"><img src="/assets/images/logo_login.svg" alt="Logo" /></NavLink>
                </div>
                <div className="d-table d-table__middle">
                    <div>
                        <form name="loginForm" autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="center-content">
                                <h2>Reset your Curio Account</h2>
                                <p><b>Login Details</b></p>
                                <div className="form-group custom">
                                    <Input
                                        label="Enter Password"
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        error={this.validator.message('password', this.state.password, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div>
                                <div className="form-group custom">
                                    <Input
                                        label="Confirm Password"
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        error={this.validator.message('password', this.state.confirmPassword, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                    <div className="help-block" >
                                        {
                                            this.state.password.length > 0 && this.state.confirmPassword.length > 0
                                                ? (this.state.password === this.state.confirmPassword)
                                                    ? ""
                                                    : "Passwords doesn't match"
                                                : ""
                                        }

                                    </div>
                                </div>
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-block mb-4">Update password</button>
                                <p className="link">Or<NavLink to="/login"> Login Here</NavLink></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default ResetPassword;
