import React, { Component } from 'react';

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false
    }

    componentDidMount() {
      localStorage.setItem("INET", false)
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }
    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//curiotechnologies.com', {
              mode: 'no-cors',
            })
              .then(() => {
                localStorage.setItem("INET", false)
                this.setState({ isDisconnected: false }, () => {
                  return clearInterval(webPing)
                });
              }).catch(() => { localStorage.setItem("INET", true); this.setState({ isDisconnected: true }) })
          }, 10000);
        return;
      }
      localStorage.setItem("INET", true);
      return this.setState({ isDisconnected: true });
    }
    reloadPage(){
      window.location.reload();
    }

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          {isDisconnected && (
            <div className="internet-error">
              <p onClick={e => this.reloadPage()}>
                Please check your internet connection.
                <span>Try Again</span>
              </p>
            </div>
          )
          }
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}