import React from "react";

const expenseReportWarning = props => {
    return (
        <>
            {props.validationMessage && props.validationMessage.map((message, index) => {
                return (
                    <div key={index} className="col-lg-3">
                    <div >
                        <div className="mb-4 err_holder animation-target-left">
                            <div className="err_text_holder">
                                <p><i className="material-icons">
                                    info
                                    </i> {message}</p>
                            </div>

                            {/*<div className="text sm-padding">*/}

                            {/*/!*<p className="close-icon">*!/*/}
                            {/*/!*<i className="icon ion-md-alert mr-2 text-danger"/> <span>{message}</span>*!/*/}
                            {/*/!*</p>*!/*/}
                            {/*</div>*/}
                            <div className="clearfix" />
                        </div>
                    </div>
                    </div>
                )
            })}
             {props.auditRuleValidationsError && props.auditRuleValidationsError.map((errors, index) => {
                return (
                    <div key={index} className="col-lg-3">
                    <div >
                        <div className="mb-4 err_holder animation-target-left">
                            <div className="err_text_holder">
                                <p><i className="material-icons">
                                    info
                                    </i> {errors}</p>
                            </div>

                            {/*<div className="text sm-padding">*/}

                            {/*/!*<p className="close-icon">*!/*/}
                            {/*/!*<i className="icon ion-md-alert mr-2 text-danger"/> <span>{message}</span>*!/*/}
                            {/*/!*</p>*!/*/}
                            {/*</div>*/}
                            <div className="clearfix" />
                        </div>
                    </div>
                </div>
                )
            })}
        </>
    );
};

export default expenseReportWarning;
