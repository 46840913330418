import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { bookingServices } from 'src/_services/booking.service';
import FlightCartDetail from '../booking/checkout/item/flightDetail';
import { FlightCartItem } from "../booking/checkout/item/flightItem";
import HotelCartDetail from '../booking/checkout/item/hotelDetail';
import FlightComboCartItem from '../booking/checkout/item/comboFlightItem';
import { HotelCartItem } from "../booking/checkout/item/hotelItem";
import FlightCartComboDetail from "../booking/checkout/item/comboFlightDetail";
import Moment from 'react-moment';
import { userService } from 'src/_services';
import { TextArea } from 'src/_components/common/_forms';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { alertConstants } from 'src/_constants';
import NumberFormat from 'react-number-format';
import Loader from 'src/_components/common/Loader';
import { Bar } from 'react-chartjs-2';
import AsyncSelect from "react-select/lib/Async";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getFullUserName, checkValidityBeforeSubmit } from "src/utils/util";
import CabCartItem from '../booking/checkout/item/cabItem';
import CabCartDetail from '../booking/checkout/item/cabCartDetails';
import { TrainCartItem } from '../booking/checkout/item/trainItem';
import TrainCartDetail from '../booking/checkout/item/trainDetail';

class TripTravelRequestPage extends Component {
  constructor(props) {
    super(props)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.state = {
      isSubmitClick: false,
      tripDetails: [],
      currentItem: [],
      history: [],
      type: "",
      selectedItem: "",
      flightDetailView: [],
      approvals: [],
      hotelDetailView: "",
      CabDetailView: "",
      trainDetailView: [],
      choosedStatus: '',
      choosedName: 'Heading',
      userList: [],
      choosedUser: [],
      comments: '',
      overAllPerDiem: 0,
      companyId: localStorage['440a28'],
      loading: true,
      approveListId: props.match.params.id,
      charData: {
        labels: ['Airfare', 'Hotel', 'Car', 'Train', 'Bus'],
        datasets: [
          {
            label: 'Budget',
            data: [
              8000.00, 4000.00, 0.00, 0.00
            ],
            backgroundColor: [
              '#377DFF',
              '#377DFF',
              '#377DFF',
              '#377DFF'
            ],
            borderWidth: 0
          },
          {
            label: 'Actual',
            data: [
              7000.00, 2000.00, 0.00, 0.00
            ],
            backgroundColor: [
              '#ff7f72',
              '#ff7f72',
              '#ff7f72',
              '#ff7f72'
            ],
            borderWidth: 0
          }
        ]
      }
    }
  }
  componentDidMount() {
    this.getDetails()
  }
  openAuditRule() {
    $("#audit-rule-container").addClass("show");
  }
  closeAuditRule() {
    $("#audit-rule-container").removeClass("show");
  }
  getDetails = () => {
    setTimeout(async () => {
      var resp = await bookingServices.getWaitingForApproveById(this.state.approveListId)
      if (resp) {
        (resp.result && resp.result.TRIP_DATA) ?
          this.setState({
            tripDetails: resp.result.TRIP_DATA
          }) : this.setState({
            tripDetails: []
          });
        var newArrayDataOfOjbect = Object.values(resp.result.info.steps)
        this.setState({
          currentItem: resp.result.current,
          approvals: newArrayDataOfOjbect
        })
        if (this.state.tripDetails) {
          const { tripDetails } = this.state;
          if (tripDetails && tripDetails.itenary && tripDetails.itenary.data && tripDetails.itenary.data.flightData && tripDetails.itenary.data.flightData.length !== 0) {
            if (tripDetails.itenary.data.flightData[0].fare) {
              this.chooseFlight(tripDetails.itenary.data.flightData[0], 'combo', `flight${0}`)
            } else {
              this.chooseFlight(tripDetails.itenary.data.flightData[0], 'flight', `flight${0}`)
            }
          } else if (tripDetails && tripDetails.itenary && tripDetails.itenary.data && tripDetails.itenary.data.hotelData && tripDetails.itenary.data.hotelData.length !== 0) {
            this.chooseHotel(tripDetails.itenary.data.hotelData[0], "hotel", `hotel${0}`)
          }
          else if (getValue(tripDetails, "itenary.data.carData", []).length !== 0) {
            this.chooseCab(
              getValue(tripDetails, "itenary.data.carData[0]", []),
              "cab",
              `cab${0}`
            );
          } else if (getValue(tripDetails, "itenary.data.trainData", []).length !== 0) {
            this.chooseTrain(
              getValue(tripDetails, "itenary.data.trainData[0]", []),
              "train",
              `train${0}`
            );
          }
        }
        if (resp.result.TRIP_DATA.Expense && resp.result.TRIP_DATA.Expense[0]) {
          var item = 0;
          resp.result.TRIP_DATA.Expense[0].perDiemDetails.forEach(element => {
            item += element.actualCurrencyAmount
          });
          this.setState({
            overAllPerDiem: item
          })
        }
      }
      this.setState({ loading: false })
    }, 5000);

  }
  updateStatus = async (data) => {
    this.setState({ userList: [], choosedUser: '', comments: '', choosedStatus: data.key, choosedName: data.button })
  }
  // chooseUser = (data) => {
  //     this.setState({ userList: [], choosedUser: data })
  // }



  getSearchUser = async (searchTerm) => {
    return userService.getUserList(searchTerm, this.state.companyId).then((data) => {
      return data?.result?.map(list => {
        return {
          label: getFullUserName(list.fieldData) + ' ' + list.email,
          value: list.id,
          email: list.email,
          userId: list.id
        };
      });
    })
  }

  handleUserSelection = user => {
    this.setState({
      choosedUser: user
    })
  };
  // getSearchUser = async (e) => {
  //     if (e.target.value) {
  //         var resp = await userService.getUserList(e.target.value, this.state.companyId)
  //         if (resp) {
  //             this.setState({
  //                 userList: resp.result
  //             })
  //         }
  //     } else {
  //         this.setState({
  //             userList: []
  //         })
  //     }
  // }
  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " m " : " m ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  chooseFlight = (e, type, key) => {
    this.setState({
      flightDetailView: e,
      type: type
    })
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        flightDetailView: "",
        type: ""
      })
    } else {
      this.setState({
        flightDetailView: e,
        selectedItem: key
      })
    }
  };
  chooseHotel = (e, type, key) => {
    this.setState({
      hotelDetailView: e,
      type: type
    })
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        hotelDetailView: "",
        type: ""
      })
    } else {
      this.setState({
        selectedItem: key
      })
    }
  };
  chooseCab = (e, type, key) => {
    this.setState({
      CabDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        CabDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        selectedItem: key
      });
    }
  }
  chooseTrain = (e, type, key) => {
    this.setState({
      trainDetailView: e,
      type: type
    });
    if (this.state.selectedItem === key) {
      this.setState({
        selectedItem: "",
        trainDetailView: "",
        type: ""
      });
    } else {
      this.setState({
        selectedItem: key
      });
    }
  }
  reset = () => {
    this.setState({
      choosedStatus: '',
      userList: [],
      choosedUser: [],
      comments: '',
    })
  }
  handleChange = (e) => {
    this.setState({
      comments: e.target.value
    })
  }
  submitRequest = async (data) => {
    this.setState({ isSubmitClick: true });
    var req = []
    if (data === "APPROVE") {
      req = {
        [data]: {}
      }
    } else if (data === "ADD_NEW_STEP") {
      req = {
        [data]: {
          userId: this.state?.choosedUser?.userId,
          comments: this.state.comments
        }
      }
    } else if (data === "REJECT") {
      req = {
        [data]: {
          comments: this.state.comments
        }
      }
    } else if (data === "SEND_BACK_EMPLOYEE") {
      req = {
        [data]: {
          comments: this.state.comments
        }
      }
    }

    let isEmailProvided = false;
    if (data === "ADD_NEW_STEP") {
      isEmailProvided = checkValidityBeforeSubmit(req[data]);
      if (isEmailProvided) {
        var resp = await bookingServices.updateApproveList(this.state.approveListId, { data: req })
        if (resp) {
          this.setState({ isSubmitClick: false });
          $('.close').click()
          this.props.history.push('/trips-approve/trips');
          toast.success(alertConstants.UPDATED_SUCCESSFULLY)
        } else {
          this.setState({ isSubmitClick: false });
        }
      } else {
        toast.warn('Please fill all the fields.');
        this.setState({ isSubmitClick: false });
      }
    } else {


      var resp = await bookingServices.updateApproveList(this.state.approveListId, { data: req })
      if (resp) {
        this.setState({ isSubmitClick: false });
        $('.close').click()
        this.props.history.push('/trips-approve/trips');
        toast.success(alertConstants.UPDATED_SUCCESSFULLY)
      } else {
        this.setState({ isSubmitClick: false });
      }
    }
  }
  render() {
    const { tripDetails, currentItem, history, flightDetailView, hotelDetailView, type, approvals, userList, loading } = this.state;
    return (
      <div>
        <div id="audit-rule-container" className="audit-trail bg-white admin-tabs">
          <button className="btn btn-nothing close-trail change-top"
            onClick={e => this.closeAuditRule()}
          >
            <i className="material-icons">close</i>
          </button>
          <ul className="expense-tabs px-padding nav nav-tabs navtabs-custom p-0" id="nav-tab123" role="tablist">
            <li >
              <a href="#" className="nav-item nav-link active"
                id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"
              >Audit Trail</a>
            </li>
            <li>
              <a href="#" className="nav-item nav-link" id="nav-request1-tab" data-toggle="tab" href="#nav-request1" role="tab" aria-controls="nav-request1" aria-selected="false">Amount Trail</a>
            </li>
          </ul>
          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className="shape-name p-3">
                <div className="float-left user-name">
                  <img
                    src="/assets/images/booking/user_11.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="right">
                  <p className="mb-0 text">
                    <b>Christine Reyes</b>
                  </p>
                  <div className="float-left">
                    <p className="muted-p mb-0 ">
                      Level 3 Approver
                                        </p>
                  </div>
                  <div className="float-right">
                    <p className="muted-p mb-0">20m ago</p>
                  </div>
                  <div className="clearfix" />
                  <div className="bg-table rounded py-2 px-3 mt-1">
                    <p className="mb-0"><span className="text-success"> <i className="material-icons fs-14">check_circle </i> </span> Approved Itinerary</p>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="border-top shape-name p-3">
                <div className="float-left user-name">
                  <img
                    src="/assets/images/booking/user_11.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="right">
                  <p className="mb-0 text">
                    <b>Christine Reyes</b>
                  </p>
                  <div className="float-left">
                    <p className="muted-p mb-0 ">
                      Level 3 Approver
                                        </p>
                  </div>
                  <div className="float-right">
                    <p className="muted-p mb-0">20m ago</p>
                  </div>
                  <div className="clearfix" />
                  <div className="bg-table rounded py-2 px-3 mt-1">
                    <p className="mb-0"><span className="text-danger"> <i className="material-icons fs-14">cancel</i> </span> Rejected Itinerary</p>
                    <p className="mb-0">Comment: The flights are not satisfactory, Kindly rework the itinerary and resubmit</p>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="border-top shape-name p-3">
                <div className="float-left user-name">
                  <img
                    src="/assets/images/booking/user_11.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="right">
                  <p className="mb-0 text">
                    <b>Christine Reyes</b>
                  </p>
                  <div className="float-left">
                    <p className="muted-p mb-0 ">
                      Level 3 Approver
                                        </p>
                  </div>
                  <div className="float-right">
                    <p className="muted-p mb-0">20m ago</p>
                  </div>
                  <div className="clearfix" />
                  <div className="bg-table rounded py-2 px-3 mt-1">
                    <p><span className="text-black">
                      <i className="material-icons fs-14">swap_horizontal_circle</i> </span>
                                            Changed Flight
                                        </p>
                    <ul className="travel-tabs">
                      <p className="font-weight-bold mb-2">Previous Flight</p>
                      <li className="itinerary-heading">
                        <div className="f-container">
                          <div className="left-f">
                            <div className="left-f-div">
                              <div className="f-cont f-logo">
                                <img width="50" src="https://s3-ap-south-1.amazonaws.com/fligth-logo/1555734160919airIndia.png" alt="images" /></div>
                              <div className="f-cont f-time">
                                <b>12:40 -  03:25</b><br />
                                <span>AIR INDIA</span>
                              </div>
                              <div className="f-cont f-stop">
                                <b>0 Stop  </b>
                              </div>
                              <div className="clearfix"></div></div></div><div className="right-f single"><h5>INR &nbsp;<span>3,950</span></h5>
                          </div>
                          <div className="clearfix">
                          </div>
                        </div>
                      </li>
                      <p className="font-weight-bold mb-2">New Flight</p>
                      <li className="itinerary-heading">
                        <div className="f-container">
                          <div className="left-f">
                            <div className="left-f-div">
                              <div className="f-cont f-logo">
                                <img width="50" src="https://s3-ap-south-1.amazonaws.com/fligth-logo/1555734160919airIndia.png" alt="images" /></div>
                              <div className="f-cont f-time">
                                <b>12:40 -  03:25</b><br />
                                <span>AIR INDIA</span>
                              </div>
                              <div className="f-cont f-stop">
                                <b>0 Stop  </b>
                              </div>
                              <div className="clearfix"></div></div></div><div className="right-f single"><h5>INR &nbsp;<span>3,950</span></h5>
                          </div>
                          <div className="clearfix">
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="border-top shape-name p-3">
                <div className="float-left user-name">
                  <img
                    src="/assets/images/booking/user_11.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="right">
                  <p className="mb-0 text">
                    <b>Christine Reyes</b>
                  </p>
                  <div className="float-left">
                    <p className="muted-p mb-0 ">
                      Level 3 Approver
                                        </p>
                  </div>
                  <div className="float-right">
                    <p className="muted-p mb-0">20m ago</p>
                  </div>
                  <div className="clearfix" />
                  <div className="bg-table rounded py-2 px-3 mt-1">
                    <p className="mb-0"><span className="text-danger"> <i className="material-icons fs-14">cancel</i> </span> Rejected Itinerary</p>
                    <p className="mb-0">Comment: The flights are not satisfactory, Kindly rework the itinerary and resubmit</p>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>

            <div className="tab-pane fade" id="nav-request1" role="tabpanel" aria-labelledby="nav-request1-tab">
              <div className="audit-rule-heading">
                <div className="row">
                  <div className="col-md-8 pt-3">
                    Itinerary Details
                                    </div>
                  <div className="col-md-4">
                    <div className="form-group mb-0 custom labelup">
                      <label>Currency</label>

                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          USD
                                                </button>
                        <div className="dropdown-menu">
                          <button
                            className="dropdown-item"
                          >USD
                                                    </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="audit-rule-body">
                <div className="">
                  <table className="table">
                    {/* <thead>
                                        <tr>
                                            <th className="align-middle">
                                                Itinerary Details
                                            </th>
                                            <th>
                                                <div className="form-group mb-0 custom labelup">
                                                    <label>Currency</label>

                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn dropdown-toggle"
                                                            data-toggle="dropdown"
                                                        >
                                                            USD
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <button
                                                                className="dropdown-item"
                                                            >USD
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead> */}
                    <tbody>
                      <tr>
                        <td className="border-top-0 align-middle">
                          <p className="mb-0 text-black font-weight-bold l-10">Cost on Submission</p>
                          <small className="muted-p">27 May 2019 3:30PM</small>
                        </td>
                        <td className="text-right border-top-0 align-middle">
                          <p className="mb-0 text-black font-weight-bold">6448</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <p className="mb-0 text-black l-10">Cost on Submission</p>
                          <small className="muted-p">27 May 2019 3:30PM</small>
                        </td>
                        <td className="text-right align-middle">
                          <p className="mb-0 text-black ">6448</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <p className="mb-0 text-black l-10">Cost on Submission</p>
                          <small className="muted-p">27 May 2019 3:30PM</small>
                        </td>
                        <td className="text-right align-middle">
                          <p className="mb-0 text-black ">6448</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle">
                          <p className="mb-0 text-black font-weight-bold l-10">Cost on Submission</p>
                          <small className="muted-p">27 May 2019 3:30PM</small>
                        </td>
                        <td className="text-right align-middle">
                          <p className="mb-0 text-black font-weight-bold">6448</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
        {loading &&
          <div
            className="modal fade show d-block"
            style={{ "backgroundColor": 'rgba(0,0,0,0.6)' }}
            id="repricingModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="repricingModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <span className="close d-none" data-dismiss="modal" aria-label="Close"></span>
                <div className="modal-middle pm-30">
                  <div className="text-center py-5">
                    <div className="loader-css"></div>
                    <h3 className=" mt-4 text-dark">Repricing the Amount...</h3>
                    <p>Please wait for a while.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {!loading &&
          <div>

            {currentItem.TRIP_ID &&
              <div className="p-padding pb-0">
                <div className="request--cost white-card mb-0 p-3">
                  <div className="row">
                    <div className="col-xl-7 col-md-6">
                      {(currentItem.actionButton.length === 0 && currentItem.STATUS === "Approved") ?
                        <h6 className="sub-heading">Approved Request
                                            </h6>
                        :
                        <h6 className="sub-heading">New Request
                                            </h6>
                      }
                      {/* -
                                    {currentItem.TRIP_ID} -
                                {currentItem && approvals[currentItem && currentItem.currentOrderNo] &&
                                            approvals[currentItem && currentItem.currentOrderNo].intialStatus
                                        } */}
                      <div className="text-left">
                        {/* <div className="col-md-3 border-right">
                                        <div className="shape-name lg trans">
                                            <div className="left icon">
                                                <i className="material-icons">done</i>
                                            </div>
                                            <div className="right">
                                                <p className="text">Approved Amount</p>
                                                <h6 className="heading">
                                                    {tripDetails.itenary && tripDetails.itenary.BASE_CURRENCY}
                                                    {tripDetails.itenary && tripDetails.itenary.REQUESTED_AMOUNT}
                                                </h6>
                                            </div>
                                        </div>
                                    </div> */}

                        <div className="d-inline-block mr-3 pr-3 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon success">
                              <i className="material-icons">done</i>
                            </div>
                            <div className="right">
                              <p className="text">Requested Amount</p>
                              <h6 className="heading">
                                {tripDetails.itenary && tripDetails.itenary.BASE_CURRENCY}
                                                                &nbsp;
                                                                <NumberFormat value={tripDetails.itenary && parseInt(tripDetails.itenary.REQUESTED_AMOUNT).toFixed(2)} displayType={'text'} thousandSeparator={true} />

                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="d-inline-block mr-4 pr-3 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon orange">
                              <i className="material-icons">cached</i>
                            </div>
                            <div className="right">
                              <p className="text">Reprice Amount</p>
                              <h6 className="heading">
                                {tripDetails.itenary && tripDetails.itenary.BASE_CURRENCY} 0.00
                                                            </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-inline-block mr-4 pr-3 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon orange">
                              <i className="material-icons">cached</i>
                            </div>
                            <div className="right">
                              <p className="text">Difference</p>
                              <h6 className="heading">
                                {tripDetails.itenary && tripDetails.itenary.BASE_CURRENCY}
                                                                &nbsp;
                                                                 <NumberFormat value={tripDetails.itenary && parseInt(tripDetails.itenary.DIFFRENCE).toFixed(2)} displayType={'text'} thousandSeparator={true} />

                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-inline-block mr-4 pr-3 border-right">
                          <div className="shape-name lg round-trans">
                            <div className="left icon red">
                              <i className="material-icons">done</i>
                            </div>
                            <div className="right">
                              <p className="text">Total Amount</p>
                              <h6 className="heading">
                                {tripDetails.itenary && tripDetails.itenary.BASE_CURRENCY}
                                                                &nbsp;
                                                                <NumberFormat value={tripDetails.itenary && parseInt(tripDetails.itenary.TRIP_COST).toFixed(2)} displayType={'text'} thousandSeparator={true} />

                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                      <div className="position-relative">
                        <div className="dots-three ">
                          {/* <div class="dropdown more-dropdown show">
                                                        <button class="btn btn-nothing dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                            <i class="material-icons">more_vert</i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="bottom-end" style={{ "right": "0", "left": "auto" }}>
                                                            <button class="dropdown-item" type="button">Reschedule</button>
                                                            <button class="dropdown-item" type="button">Cancel</button>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="btn-group-margin ml-5">
                          {(currentItem.actionButton.length !== 0) &&
                            <h6 className="sub-heading">Action</h6>
                          }
                          {(currentItem.actionButton.length === 0) &&
                            <div>
                              {(currentItem.STATUS) &&
                                <div>
                                  <h6 className="sub-heading">Status</h6>
                                  {(currentItem.STATUS === "Approved") ?
                                    <div className="px-3 py-2 bg-table d-inline-block text-success">
                                      <i className="material-icons fs-14 align-middle mr-1">thumb_up</i>{currentItem.STATUS}
                                    </div>
                                    : (currentItem.STATUS === "Rejected") ?
                                      <div className="px-3 py-2 bg-table d-inline-block text-danger">
                                        <i className="material-icons fs-14 align-middle mr-1">thumb_down</i>{currentItem.STATUS}
                                      </div> :
                                      <div className="px-3 py-2 bg-table d-inline-block">
                                        {currentItem.STATUS}
                                      </div>
                                  }
                                </div>
                              }
                            </div>
                          }
                          {currentItem.actionButton.map((list, key) => {
                            if (list.key === "APPROVE") {
                              return (
                                <button onClick={e => this.updateStatus(list)} key={key}
                                  className="btn btn-approve btn-color hover-v"
                                  data-target="#submitModalRequest"
                                  data-toggle="modal">
                                  <i className="material-icons">check_circle_outline </i>
                                  <div className="hover-name with-br bottom">{list.button}</div>
                                </button>
                              )
                            }
                            if (list.key === "SEND_BACK_EMPLOYEE") {
                              return (
                                <button onClick={e => this.updateStatus(list)} key={key}
                                  className="btn btn-sendback btn-color hover-v"
                                  data-target="#submitModalRequest"
                                  data-toggle="modal">
                                  <i className="material-icons">arrow_back </i>
                                  <div className="hover-name with-br bottom">{list.button}</div>
                                </button>
                              )
                            }
                            if (list.key === "REJECT") {
                              return (
                                <button onClick={e => this.updateStatus(list)} key={key}
                                  className="btn btn-reject hover-v btn-color"
                                  data-target="#submitModalRequest"
                                  data-toggle="modal">
                                  <i className="material-icons">not_interested </i>
                                  <div className="hover-name with-br bottom">{list.button}</div>
                                </button>
                              )
                            }
                            if (list.key === "ADD_NEW_STEP") {
                              return (
                                <button onClick={e => this.updateStatus(list)} key={key}
                                  className="btn btn-grey1 btn-color hover-v"
                                  data-target="#submitModalRequest"
                                  data-toggle="modal">
                                  <i className="material-icons">add </i>
                                  <div className="hover-name with-br bottom">{list.button}</div>
                                </button>
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="p-padding">
              <h6 className="sub-heading">Requested By</h6>
              <div className="outdivision">
                <div className="profile-panel">
                  <div className="row">
                    <div className="col-md-3">
                      {tripDetails.itenary && tripDetails.itenary.userDeatils &&
                        <div className="shape-name lg mb-0">
                          {(tripDetails.itenary.userDeatils && tripDetails.itenary.userDeatils.profileImage) ?
                            <div className="left img" style={{ backgroundImage: `url(${tripDetails.itenary.userDeatils && tripDetails.itenary.userDeatils.profileImage})` }}>
                            </div>
                            :
                            <div className="left">
                              {tripDetails.itenary.userDeatils.fieldData &&
                                tripDetails.itenary.userDeatils.fieldData.map((list, key) => {
                                  return (
                                    <span key={key}>
                                      {list.columnName == "EMPLOYEE_FIRST_NAME" &&
                                        list.fieldValue.charAt(0)}
                                      {list.columnName == "EMPLOYEE_LAST_NAME" &&
                                        list.fieldValue.charAt(0)}
                                    </span>
                                  );
                                })}
                            </div>}
                          <div className="right">
                            <h6 className="heading">

                              {tripDetails.itenary.userDeatils.fieldData.map((list, key) => {
                                return (
                                  <span key={key}>
                                    {list.columnName == "EMPLOYEE_FIRST_NAME" &&
                                      list.fieldValue}
                                    {list.columnName == "EMPLOYEE_LAST_NAME" &&
                                      list.fieldValue}
                                  </span>
                                );
                              })}
                              {/* - {tripDetails.itenary.userDeatils.email} */}
                            </h6>
                            <p className="text">
                              {tripDetails.travelRequest && tripDetails.travelRequest.policy &&
                                tripDetails.travelRequest.policy.policyName}
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      }
                    </div>
                    {tripDetails.travelRequest &&
                      tripDetails.travelRequest.data &&
                      tripDetails.travelRequest.data.personalDayOff &&
                      <div className="col-md-4">
                        {/* <span className="fs-12">Personal Day Off</span><br /><h6 className="fs-14">
                                            {tripDetails.travelRequest.data.personalDayOff.personalDayOffFrom}
                                            -
                                        {tripDetails.travelRequest.data.personalDayOff.personalDayOffTO}
                                        </h6> */}
                      </div>
                    }
                    <div className="col-md-5">
                      <div className="btn-group-margin text-right">
                        {/* <button className="btn">View Budget</button> */}
                        {/* <button className="btn">Edit Request</button> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-right">
                        {/* <button
                                                    onClick={e => this.openAuditRule()}
                                                    className="btn">Audit Trail</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="innerdiv bg-white border-top-light py-3 px-4">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">E</div>
                        <div className="right">
                          <p className="text op-less">
                            Email
                                            </p>
                          <h6 className="heading fw-sbold">{tripDetails.itenary && tripDetails.itenary.userDeatils && tripDetails.itenary.userDeatils.email}</h6>
                        </div>
                      </div>
                    </div>
                    {currentItem.TRIP_ID &&
                      <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="shape-name mb-4">
                          <div className="left l-grey">T</div>
                          <div className="right">
                            <p className="text op-less">
                              Trip Id
                                            </p>
                            <h6 className="heading fw-sbold">{currentItem.TRIP_ID}</h6>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">S</div>
                        <div className="right">
                          <p className="text op-less">
                            Status
                                            </p>
                          <h6 className="heading fw-sbold">{currentItem.STATUS ? currentItem.STATUS : currentItem.TRIP_ID && currentItem && approvals[currentItem && currentItem.currentOrderNo] &&
                            approvals[currentItem && currentItem.currentOrderNo].intialStatus
                          }</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                      <div className="shape-name mb-4">
                        <div className="left l-grey">P</div>
                        <div className="right">
                          <p className="text op-less">
                            Personal Days Off
                                            </p>
                          {tripDetails.travelRequest &&
                            tripDetails.travelRequest.data &&
                            tripDetails.travelRequest.data.personalDayOff && tripDetails.travelRequest.data.personalDayOff ?
                            <h6 className="heading fw-sbold">
                              {tripDetails.travelRequest.data.personalDayOff.personalDayOffFrom}
                              {" "}-{" "}
                              {tripDetails.travelRequest.data.personalDayOff.personalDayOffTO}
                            </h6>
                            : "-"
                          }
                        </div>
                      </div>
                    </div>
                    {tripDetails.travelRequest && tripDetails.travelRequest.data && tripDetails.travelRequest.data.REQUEST && tripDetails.travelRequest.data.REQUEST.entryFields &&
                      tripDetails.travelRequest.data.REQUEST.entryFields.map((list, key) => {
                        return (
                          <div className="col-xl-3 col-lg-4 col-md-6" key={key}>
                            <div className="shape-name mb-4">
                              <div className="left l-grey">{list.labelName && list.labelName.length && list.labelName[0]}</div>
                              <div className="right">
                                <p className="text op-less">{list.labelName}</p>
                                <h6 className="heading fw-sbold">
                                  {(list.dataType == "connected_list" || list.dataType == "different_list") ?
                                    list.connectedListData && list.connectedListData.listManagementData && list.connectedListData.listManagementData.map((item, keyInt) => {
                                      if (item.id === list.fieldValue || item.id === list.defaultValue) {
                                        return (
                                          <span key={keyInt}>{item.itemName}</span>
                                        )
                                      }
                                    })
                                    : list.fieldValue || "-"
                                  }
                                </h6>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div >
              </div >
            </div>

            {/*
                        <div className={this.state.companyId == "5dc5197e1382a2538aa3a8ca" ? "p-padding d-none" : "p-padding d-block"}>
                            <div className="white-card">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="p-padding">
                                            <div className="">
                                                <Bar
                                                    data={this.state.charData}
                                                    height="400px"
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <div className="row border-bottom">
                                                    <div className="col-md-6 py-2">
                                                        Trips Completed
                                                    </div>
                                                    <div className="col-md-6 py-2">
                                                        <div className="text-right">
                                                            INR 12293.00
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row border-bottom">
                                                    <div className="col-md-6 py-2">
                                                        Trips Progress
                                                    </div>
                                                    <div className="col-md-6 py-2">
                                                        <div className="text-right">
                                                            INR 000.00
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 py-2">
                                                        Trips Forecast
                                                    </div>
                                                    <div className="col-md-6 py-2">
                                                        <div className="text-right">
                                                            INR 000.00
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 border-left">
                                        <div className="py-3 budget-tabs">
                                            <ul className="expense-tabs px-padding nav nav-tabs p-0" id="nav-tab123" role="tablist">
                                                <li className="audit-step">
                                                    <a href="#" className="nav-item nav-link active s1 rounded-left"
                                                        id="nav-quarterly-tab" data-toggle="tab" href="#nav-quarterly" role="tab" aria-controls="nav-quarterly" aria-selected="true"
                                                    >Quarterly</a>
                                                </li>
                                                <li className="audit-step">
                                                    <a href="#" className="nav-item nav-link s1 rounded-right" id="nav-service-tab" data-toggle="tab" href="#nav-service" role="tab" aria-controls="nav-service" aria-selected="false"> Service Based</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div className="tab-pane fade show active" id="nav-quarterly" role="tabpanel" aria-labelledby="nav-quarterly-tab">
                                                <div className="table-container mx-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-selected">
                                                            <thead>
                                                                <tr>
                                                                    <th>DESCRIPTION</th>
                                                                    <th>BUDGET</th>
                                                                    <th>ACTUAL</th>
                                                                    <th>VARIABLE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-warning mr-2"></span>Quarterly 1</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-danger mr-2"></span>Quarterly 2</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-success mr-2"></span>Quarterly 3</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-primary mr-2"></span>Quarterly 4</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr className="border-bottom-0">
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-warning mr-2"></span>Current Dept</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
                                                <div className="table-container mx-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-selected">
                                                            <thead>
                                                                <tr>
                                                                    <th>DESCRIPTION</th>
                                                                    <th>BUDGET</th>
                                                                    <th>ACTUAL</th>
                                                                    <th>VARIABLE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-warning mr-2"></span>Air</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-danger mr-2"></span>Hotel</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-success mr-2"></span>Car</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-primary mr-2"></span>Other Expenses</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                                <tr className="border-bottom-0">
                                                                    <td>
                                                                        <p><span className="coloured__dot rounded-circle bg-warning mr-2"></span>Current Dept</p>
                                                                        <p className="op-less">01/01/2018 - 31/03/2018</p>
                                                                    </td>
                                                                    <td>INR 10,000.00</td>
                                                                    <td>INR 8,000.00</td>
                                                                    <td>INR 10,000.00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}


            {tripDetails.travelRequest &&
              tripDetails.travelRequest.data &&
              tripDetails.travelRequest.data.ALLOCATION &&
              <div className="p-padding pt-0">
                <div className="main-box">
                  <div className="p-3 bg-light border-bottom-light">
                    <h6 className="sub-heading px-3 mb-0">Allocation</h6>
                    <div className="clearfix"></div>
                  </div>
                  <div className="table-container mx-0">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            {tripDetails.travelRequest.data &&
                              tripDetails.travelRequest.data.ALLOCATION &&
                              tripDetails.travelRequest.data.ALLOCATION.entryFields &&
                              tripDetails.travelRequest.data.ALLOCATION.entryFields[0] &&
                              tripDetails.travelRequest.data.ALLOCATION.entryFields[0].map((value, headKey) => {
                                if (headKey < tripDetails.travelRequest.data.ALLOCATION.entryFields[0].length - 1 && value.activeStatus !== false) {
                                  return (
                                    <th key={headKey}>{value.fieldName}</th>
                                  )
                                }
                              })}
                            <th>
                              {tripDetails.travelRequest.data &&
                                tripDetails.travelRequest.data.ALLOCATION &&
                                tripDetails.travelRequest.data.ALLOCATION.allocatedIn
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tripDetails.travelRequest &&
                            tripDetails.travelRequest.data &&
                            tripDetails.travelRequest.data.ALLOCATION &&
                            tripDetails.travelRequest.data.ALLOCATION.entryFields &&
                            tripDetails.travelRequest.data.ALLOCATION.entryFields.map((value, key) => {
                              if (value.activeStatus !== false)
                                return (
                                  <tr key={key}>
                                    {value.map((allocationList, allocateKey) => {
                                      if (allocationList.activeStatus !== false) {
                                        return (
                                          <td key={allocateKey}>
                                            {(allocationList.dataType == "connected_list" || allocationList.dataType == "different_list") ?
                                              allocationList.fieldItemName : allocationList.fieldValue || "-"
                                              // allocationList.connectedListDatas && allocationList.connectedListDatas && allocationList.connectedListDatas.itemName
                                              // : allocationList.fieldValue || "-"
                                            }
                                            {/* {allocationList.fieldValue} */}
                                          </td>
                                        )
                                      }
                                    })
                                    }
                                  </tr>
                                )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }
            {tripDetails.Expense && tripDetails.Expense.map((list, key) => {
              return (
                <div key={key}>
                  <div className="p-padding pt-0" >
                    <div className="main-box">
                      <h6 className="py-3 bg-light sub-heading px-4 border-bottom-light">Travel Allowance/Per Diem</h6>
                      <div className="table-container mx-0">
                        <div className="table-responsive">
                          <table className="table  mb-0">
                            <thead>
                              <tr>
                                <th className="border-0">Journey</th>
                                <th className="border-0">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.perDiemDetails &&
                                <tr>
                                  <td className="border-0">{list.noOfDaysJourney} Days</td>

                                  <td className="border-0">
                                    {list.perDiemDetails[0] && list.perDiemDetails[0].actualCurrencyType}
                                                                        &nbsp;
                                                                       {(this.state.overAllPerDiem) ? this.state.overAllPerDiem : 0}
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        <div id="demo" className="collapse fade-in">
                          <div className="table-responsive">
                            <table className="table table-striped mb-0">
                              <thead>
                                <tr>
                                  <th>Expense Type</th>
                                  <th>Location</th>
                                  <th>Date</th>
                                  <th>Base Amount</th>
                                  <th>Actual Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list.perDiemDetails.map((item, keyIndex) => {
                                  return (
                                    <tr key={keyIndex}>
                                      <td>{item.expenseType}</td>
                                      <td>{item.location}</td>
                                      <td>{item.date}</td>
                                      <td> {item.baseCurrencyType} {item.baseCurrencyAmount}</td>
                                      <td> {item.actualCurrencyType} {item.actualCurrencyAmount}</td>

                                    </tr>
                                  )
                                })}

                              </tbody>
                            </table>
                          </div>
                        </div>
                        {(this.state.overAllPerDiem !== 0) &&
                          <button
                            type="button"
                            className="btn btn-nothing bg-light btn-block py-2 show-more-btn collapsed text-primary btn-no-radius"
                            data-toggle="collapse"
                            data-target="#demo"
                          >
                            <span className="more">Show More</span>
                            <span className="less">Show Less</span>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="p-padding pt-0">
                    {list.additionalExpense && list.additionalExpense[0] &&
                      <div className="main-box">
                        <h6 className="py-3 sub-heading px-4 border-bottom-light bg-light">Expense</h6>
                        <div className="table-container mx-0">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Expense Type</th>
                                  {list.additionalExpense[0] && list.additionalExpense[0].fieldData && list.additionalExpense[0].fieldData.map((expenseItem, key) => {
                                    return (
                                      <th key={key}>{expenseItem.labelName}</th>
                                    )
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {list.additionalExpense && list.additionalExpense.map((expenseList, key) => {
                                  return (
                                    <tr>
                                      <td>
                                        {expenseList?.expenseObj?.expenseTypeName}
                                      </td>
                                      {expenseList && expenseList.fieldData.map((value, valueKey) => {
                                        return (
                                          <td key={valueKey}>
                                            {(value.dataType == "connected_list" || value.dataType == "different_list") ?
                                              value.connectedListData && value.connectedListData.listManagementData && value.connectedListData.listManagementData.map((item, keyInt) => {
                                                if (item.id === value.fieldValue || item.id === value.defaultValue) {
                                                  return (
                                                    <span key={keyInt}>{item.itemName}</span>
                                                  )
                                                }
                                              })
                                              : (value.dataType == "search") ? value.searchName : value.fieldValue || "-"
                                            }
                                          </td>
                                        )
                                      })
                                      }
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="p-padding pt-0">
                    {list.cashAdvanceFormData && list.cashAdvanceFormData[0] &&
                      <div className="main-box">
                        <h6 className="py-3 px-4 sub-heading bg-light">Cash Advance</h6>
                        <div className="table-container mx-0">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  {list.cashAdvanceFormData[0] && list.cashAdvanceFormData[0].fieldData && list.cashAdvanceFormData[0].fieldData.map((expenseCash, key) => {
                                    return (
                                      <th key={key}>{expenseCash.labelName}</th>
                                    )
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {list.cashAdvanceFormData && list.cashAdvanceFormData.map((expenseList, key) => {
                                  return (
                                    <tr>
                                      {expenseList && expenseList.fieldData.map((value, valueKey) => {
                                        return (
                                          <td key={valueKey}>
                                            {(value.dataType == "connected_list" || value.dataType == "different_list") ?
                                              value.connectedListData && value.connectedListData.listManagementData && value.connectedListData.listManagementData.map((item, keyInt) => {
                                                if (item.id === value.fieldValue || item.id === value.defaultValue) {
                                                  return (
                                                    <span key={keyInt}>{item.itemName}</span>
                                                  )
                                                }
                                              })
                                              : (value.dataType == "search") ? value.searchName : value.fieldValue || "-"
                                            }
                                          </td>
                                        )
                                      })
                                      }
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )
            })}
            <div className="p-padding pt-0">
              <div className="white-card shadow-light mb-0">
                <div className="pad-sm">
                  <h6 className="sub-heading mb-0">Approver Flow</h6>
                </div>
                <div>
                  {approvals && approvals.map((item, key) => {
                    return (
                      <div className="approver" key={key}>
                        {(item.APPROVED) ?
                          <div className="right-icon approved">
                            <i className="material-icons">check_circle</i>
                          </div>
                          :
                          <div className="right-icon waiting">
                            <i className="material-icons">
                              hourglass_empty
                                                </i>
                          </div>
                        }
                        <div className="shape-name">
                          {(item.userDetails && item.userDetails.profileImage) ?
                            <div className="left img" style={{ backgroundImage: `url(${item.userDetails && item.userDetails.profileImage})` }}>
                            </div>
                            :
                            <div className="left">
                              {item.userDetails && item.userDetails.fieldData &&
                                item.userDetails.fieldData.map((list, key) => {
                                  return (
                                    <span key={key}>
                                      {list.columnName == "EMPLOYEE_FIRST_NAME" &&
                                        list.fieldValue.charAt(0)}
                                      {list.columnName == "EMPLOYEE_LAST_NAME" &&
                                        list.fieldValue.charAt(0)}
                                    </span>
                                  );
                                })}
                            </div>}
                          <div className="right">
                            <p className="label">Level {key + 1}</p>
                            <p className="title">
                              {item.userDetails && item.userDetails.fieldData &&
                                item.userDetails.fieldData.map((list, key) => {
                                  return (
                                    <span key={key}>
                                      {list.columnName == "EMPLOYEE_FIRST_NAME" &&
                                        list.fieldValue}
                                      {list.columnName == "EMPLOYEE_LAST_NAME" &&
                                        list.fieldValue}
                                    </span>
                                  );
                                })}
                            </p>
                            <p className="desg">{item.userDetails?.email}</p>
                            {approvals.submittedAt ? <p>Submitted on : {approvals.submittedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.submittedAt}</Moment> : "     -"} </p> : ""}
                            {approvals.approvedAt ? <p>Approved on :{approvals.approvedAt ? <Moment format='DD MMM YYYY, HH:mm'>{approvals.approvedAt}</Moment> : "  Not Yet Approved"} </p> : ""}

                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div className="p-padding pt-0 pb-0">
              <ul className="tabs-pil mb-0">
                <li>
                  <button className="btn active">New Itinerary</button>
                </li>
                {/* <li>
                            <button className="btn">Approved Itinerary</button>
                        </li> */}
              </ul>
            </div>
            <div>
              <div className="p-padding">
                <div className="row">
                  <div className="col-md-6">
                    <div className="itinerary-scroll mx-itinerary-scroll">
                      <ul className="travel-tabs">
                        {/* Flight Config Starts */}
                        {tripDetails.itenary &&
                          tripDetails.itenary.data &&
                          tripDetails.itenary.data.flightData &&
                          tripDetails.itenary.data.flightData.map((list, key) => {
                            if (list.fare) {
                              return (
                                <FlightComboCartItem
                                  isApprover={true}
                                  baseCurrency={list?.flight_searchType?.disp_currency}
                                  chooseFlight={this.chooseFlight}
                                  index={key}
                                  selectedItem={this.state.selectedItem}
                                  secondsToHms={this.secondsToHms}
                                  key={key}
                                  nonEdit={true}
                                  item={list}
                                  removeCart={this.removeFlightCart}
                                  className="show-fluctuation"
                                />
                              )
                            } else {
                              return (
                                <FlightCartItem
                                  isApprover={true}
                                  baseCurrency={list?.flight_searchType?.disp_currency}
                                  chooseFlight={this.chooseFlight}
                                  index={key}
                                  nonEdit={true}
                                  className="show-fluctuation"
                                  selectedItem={this.state.selectedItem}
                                  secondsToHms={this.secondsToHms}
                                  key={key}
                                  item={list}
                                  removeCart={this.removeFlightCart}
                                />
                              );
                            }
                          })}
                        {/* Flight Config end */}
                        {/* hotells Config Starts */}
                        {tripDetails.itenary &&
                          tripDetails.itenary.data &&
                          tripDetails.itenary.data.hotelData &&
                          tripDetails.itenary.data.hotelData.map((list, key) => {
                            return (
                              <HotelCartItem
                                isApprover={true}
                                baseCurrency={list?.hotel_currency_code}
                                chooseHotel={this.chooseHotel}
                                index={key}
                                nonEdit={true}
                                removeHotelCart={this.removeHotelCart}
                                selectedItem={this.state.selectedItem}
                                secondsToHms={this.secondsToHms}
                                key={key}
                                item={list}
                              />
                            );
                          })}
                        {/* hotells Config end */}
                        {getValue(tripDetails, "itenary.data.carData", []).map((item, key) => (
                          <CabCartItem
                            item={item}
                            chooseCab={this.chooseCab}
                            index={key}
                          />
                        ))}
                        {getValue(tripDetails, "itenary.data.trainData", []).map((item, key) => (
                          <TrainCartItem
                            item={item}
                            chooseTrain={this.chooseTrain}
                            index={key}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="itinerary-scroll mx-itinerary-scroll">
                      {type === "flight" && (
                        <FlightCartDetail
                          baseCurrency={flightDetailView?.flight_searchType?.disp_currency}
                          secondsToHms={this.secondsToHms}
                          item={flightDetailView}

                        />
                      )}
                      {type === "combo" && (
                        <FlightCartComboDetail
                          baseCurrency={flightDetailView?.flight_searchType?.disp_currency}
                          secondsToHms={this.secondsToHms}
                          item={flightDetailView}
                        />
                      )}
                      {type === "hotel" && (
                        <HotelCartDetail
                          baseCurrency={hotelDetailView?.hotel_currency_code}
                          secondsToHms={this.secondsToHms}
                          item={hotelDetailView}
                        />
                      )}
                      {type === "cab" && (
                        <CabCartDetail
                          item={this.state.CabDetailView}
                        />
                      )}
                      {type === "train" && (
                        <TrainCartDetail
                          item={this.state.trainDetailView}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div
              className="modal fade"
              id="submitModalRequest"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="submitModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <span className="close d-none" data-dismiss="modal" aria-label="Close"></span>
                  {(this.state.choosedStatus === "APPROVE") ? "" : <div className="modal-head">
                    <h5 className="modal-title">{this.state.choosedName}</h5>
                  </div>
                  }
                  <div className="modal-middle pm-30">
                    {(this.state.choosedStatus === "ADD_NEW_STEP") &&
                      <div className="">

                        <div className="form-group custom labelup">
                          <label>Search and Choose a User </label>
                          <AsyncSelect
                            isClearable={true}
                            noOptionsMessage={({ inputValue }) => inputValue ? 'No results.' : "Start typing to search..."}
                            loadOptions={this.getSearchUser}
                            value={this.state.choosedUser}
                            placeholder="Search And Select"
                            onChange={(event) => this.handleUserSelection(event)}
                          />
                        </div>
                        {/* // <div className="form-group custom">
                                                    //     <input autocomplete="off" name="searchUser" onChange={e => this.getSearchUser(e)} placeholder="&nbsp;" className="form-control" />
                                                    //     <label>Search &amp; Choose A User</label>
                                                    //     <span className="searc-icon-abs"><i className="material-icons">search</i></span>
                                                    // </div>
                                                   */}
                        <div>
                          {/* {this.state.choosedUser.length === 0 && userList &&
                                                        <ul className="search-list">
                                                            {userList.map((item, key) => {
                                                                return (
                                                                    <li key={key} onClick={e => this.chooseUser(item)}>{item.email}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    } */}
                          {/* {this.state.choosedUser &&
                                                        <span>{this.state.choosedUser.email}</span>
                                                    } */}
                        </div>
                      </div>
                    }
                    <div>
                      {((this.state.choosedStatus == "SEND_BACK_EMPLOYEE") ||
                        (this.state.choosedStatus == "REJECT") ||
                        (this.state.choosedStatus == "ADD_NEW_STEP")) &&
                        <div className="form-group">
                          <TextArea
                            data-gramm_editor="false"
                            spellCheck="false"
                            name="comments"
                            value={this.state.comments}
                            label={"Comment"}
                            controlFunc={this.handleChange}
                          />
                        </div>
                      }
                      {(this.state.choosedStatus !== "APPROVE") &&
                        <div className="btn-group-margin text-right">
                          {!this.state.isSubmitClick ?
                            <button className="btn" onClick={e => this.submitRequest(this.state.choosedStatus)}>Submit</button>
                            :
                            <button className="btn">Please Wait...</button>
                          }
                          <button className="btn" data-dismiss="modal" aria-label="Close" onClick={e => this.reset()}>Cancel</button>
                        </div>
                      }
                      {(this.state.choosedStatus === "APPROVE") &&
                        <div>
                          <div className="text-center pt-5">
                            <img src="/assets/images/confirmation_icon.svg" alt="" />
                            <h3 className=" mt-4 text-dark">Are You Sure?</h3>
                            <p>By clicking on submit, you are approving this request.</p>
                          </div>
                          <div className="btn-group-margin text-center mb-4">
                            <button className="btn" data-dismiss="modal" aria-label="Close" onClick={e => this.reset()}>No</button>
                            {!this.state.isSubmitClick ?
                              <button className="btn" onClick={e => this.submitRequest(this.state.choosedStatus)}>Submit</button>
                              :
                              <button className="btn">Please Wait...</button>
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
export default TripTravelRequestPage
