import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { userService } from '../../_services';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '../common/_forms';
import { alertConstants } from 'src/_constants';

class InviteCreatePassword extends Component {
    constructor(props) {
        localStorage.clear();
        super(props)
        this.validator = new SimpleReactValidator();
        this.state = {
            userId: '',
            email: '',
            password: '',
            confirmPassword: ''
        };
    }
    componentDidMount(){ 
      let id = this.props.match.params.id; 
      this.verifyInvitationCode(id); 
    }

    verifyInvitationCode = async (id) => {
      let payload = {
        inviteToken: id
      }
      let res = await userService.verifyInvitationCode(payload); 
      if(res){
        this.setState({
          email: res.result.email,
          userId: res.result.id
        })
      }

    }
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    loading = false
    handleSubmit = (e) => {
        e.preventDefault();  
        if (this.validator.allValid()) {
            const { email, userId, password, confirmPassword } = this.state;
            if (email && userId && password) { 
              if(password === confirmPassword){
                let payload ={
                  userId: this.state.userId,
                  password: this.state.password
                };
                userService.createPassword(payload)
                  .then(res => {
                    if(res){
                      toast.success(alertConstants.CREATED_SUCCESSFULLY);
                      this.props.history.push('/login');
                    }
                  }, error => {
                    if (error.statusCode === 422) {
                      let errors = error.message
                      Object.keys(errors).map(key => {
                            return (
                                toast.error(errors[key])
                            );
                        })
                       } else {
                        toast.error(error.message)
                            }
                  })



                // userService.updatePassword(this.state)
                //     .then(data => {
                //         toast.success(data.message)
                //         this.props.history.push('/login');
                //     }, error => {
                //         if (error.statusCode === 422) {
                //             let errors = error.message
                //             Object.keys(errors).map(key => {
                //                 return (
                //                     toast.error(errors[key])
                //                 );
                //             })
                //         } else {
                //             toast.error(error.message)
                //         }
                //     })
                  }else{
                    console.log("Passwords don't match");
                  }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }
    render() {

        return (
            <div className="login-ext">
                <div className="logo">
                    <NavLink to="/"><img src="/assets/images/logo_login.svg" alt="Logo" /></NavLink>
                </div>
                <div className="d-table d-table__middle">
                    <div>
                        <form name="loginForm" autoComplete="off" onSubmit={this.handleSubmit}>
                            <div className="center-content">
                                <h2>Create your Curio Account!</h2>
                                <p><b> {this.state.email} </b></p>
                                {/* <div className="form-group custom">
                                    <Input
                                        label="Customer Code"
                                        type="text"
                                        name="companyCode"
                                        value={this.state.customerCode}
                                        error={this.validator.message('companyCode', this.state.companyCode, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div>
                                <div className="form-group custom">
                                    <Input
                                        label="Email"
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        error={this.validator.message('email', this.state.email, 'required|email')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                </div> */}
                                <div className="form-group custom">
                                    <Input
                                        label="Password"
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        error={this.validator.message('password', this.state.password, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />

                                </div>
                                <div className="form-group custom">
                                    <Input
                                        label="Confirm Password"
                                        type="password"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        error={this.validator.message('password', this.state.confirmPassword, 'required')}
                                        controlFunc={e => this.handleChange(e)}
                                    />
                                     <div className="help-block" >
                                  {/* {(this.state.password.length > 0 && (this.state.password == this.state.confirmPassword)) ? "Passwords match." : "Passwords don't match." } */}
                                  {
                                    this.state.password.length > 0 && this.state.confirmPassword.length > 0
                                     ? (this.state.password == this.state.confirmPassword)
                                        ? "Passwords match!"
                                        : "Passwords don't match"
                                     : ""
                                  }

                                  </div>
                                </div>

                                .

                                <button type="submit" onClick={this.handleSubmit} className="btn btn-block mb-4">Create password</button>
                                {/* <p className="link">Or<NavLink to="/login"> Login Here!</NavLink></p> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
export default InviteCreatePassword;