import React from "react";
import moment from "moment";
import { getValue } from "src/_components/common/lodsh";

moment.suppressDeprecationWarnings = true;
const expenseLeftSidebarSummary = props => {
  return (
    <div className="scroll">
      <ul>
        {props.expenseEntryList &&
          props.expenseEntryList.map((list, index) => {
            let expenseTypeName =
              list.expenseType && list.expenseType.expenseTypeName
                ? list.expenseType.expenseTypeName
                : list.expenseTypeObj && list.expenseTypeObj.expenseTypeName;
            return (
              <li
                className={
                  props.selectExpenstEntryList === list.id
                    ? "active ani-expense-type-item"
                    : "ani-expense-type-item"
                }
                key={list.id}
              >
                <div className="top">
                  <div
                    className="right-side"
                    onClick={() => props.handleAllocationDetails(list.id,list.headerId)}
                  >
                    <p className="hover-v mb-0 d-inline-block mt-2">
                      {/* <img src="/assets/images/itemised.svg" alt="" />
                      <img src="/assets/images/partially-itemised.svg" alt="" />
                      <img src="/assets/images/not-itemised.svg" alt="" />
                      <span className="hover-name right-bottom less">Allocated</span> */}
                    </p>
                    {list.allocationAvailable && (
                      <p
                        className="hover-v mb-0 d-inline-block ml-3 mt-2 cursor-pointer"
                        data-target="#allocatedGraphModal"
                        data-toggle="modal"
                      >
                        {/* <img src="/assets/images/allocated.svg" alt="" /> */}
                        <img src="/assets/images/not-allocated.svg" alt="" />
                        <span className="hover-name right-bottom less">
                          Allocated
                        </span>
                      </p>
                    )}
                  </div>
                  <div
                    onClick={e => props.onSelectExpenstEntryList(list.id, list.expenseType.id)}
                    className="shape-name lg"
                  >
                    <div className="right ml-0">
                      <h6 className="heading">
                        {list && list.validation && (
                          <i className="material-icons fs-14 text-danger">
                            error
                          </i>
                        )}{" "}
                        {list.history === "true"?<img src={require("../../../../../_assets/images/warning.png")} style={{ "width": "15px" }} /> : ""}
                        {expenseTypeName && expenseTypeName}
                      </h6>
                      {list.entryForm &&
                        list.entryForm.map((date, index) => {
                          return (
                            <p key={index} className="text">
                              {date.dataType === "date" ? date.fieldValue : ""}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>

                {list.entryForm &&
                  list.entryForm.map((amount, index) => {
                    let theadArray = [];
                    theadArray = list.entryForm.map(
                      header => header.columnName
                    );
                    let AMOUNT = theadArray.indexOf("AMOUNT");

                    return amount.dataType === "amount" &&
                      amount.columnName === "AMOUNT" &&
                      amount.fieldValue ? (
                        <div
                          onClick={e => props.onSelectExpenstEntryList(list.id, list.expenseType.id)}
                          key={index}
                          className="bottom"
                        >
                          <div className="right-side">
                            <p className="label">Requested</p>
                            <h6>
                              {list.currency}{" "}
                              {list.entryForm[AMOUNT].fieldValue !== null &&
                                new Intl.NumberFormat("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }).format(list.entryForm[AMOUNT].fieldValue)}
                              <br />
                              {list.currency !== list.baseCurrency &&
                                "" +
                                list.baseCurrency +
                                " " +
                                new Intl.NumberFormat("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                }).format(list.baseAmount)}
                            </h6>
                          </div>
                          <div>
                            <p className="label">{amount.labelName}</p>
                            <h6>
                              {list.currency}{" "}
                              {new Intl.NumberFormat("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }).format(amount.fieldValue)}
                            </h6>
                          </div>
                          <div className="clearfix" />
                        </div>
                      ) : (
                        ""
                      );
                  })}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default expenseLeftSidebarSummary;
