import React, { Component } from "react";
import Loader from "src/_components/common/Loader";
import SimpleReactValidator from "simple-react-validator";
import { inject, observer } from "mobx-react";
import EditExpenseReportPolicy from "./components/expenseReport/editExpenseReportPolicy";
import { observable } from "mobx";
import { expenseReport } from "../../../_services/expenseReport";
import _ from 'lodash';
import moment from 'moment';
import { getValue, setValue } from "src/_components/common/lodsh";
import $ from 'jquery';
import { getConnectedList } from "src/_services";

@inject("expenseStore")
@observer
class EditReportHeader extends Component {
  @observable policyId = "";
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      policyTypes: [],
      companyId: localStorage["440a28"],
      selectedPolicy: "",
      policyForm: [],
      formFields: [],
      error: [],
      validationStatus: true,
      submitButtonStatus: true,
      fieldsObject: [],
      userPermission: 'employee-role',
      connectedListData: [],
      isLoading: true,
      isSubmitClick: false,
      search : [],
      loading:[]
    };
  }
  componentWillUnmount(){
    this.state = {
     search : [],
     loading:[]
   }
 }

  componentDidMount() {
    let headerId = this.props.match.params.id;
    if (headerId !== undefined && headerId !== '' && headerId !== null) {
      localStorage.setItem("headerId", headerId);
    }
    this.getPoliciesTypes();
    this.getEditHeaderReportForm();
  }

  getPoliciesTypes = async () => {
    let response = await expenseReport.getPoliciesTypes(this.state.companyId);
    if (response) {
      this.setState({ policyTypes: response });
    }
  };


  getEditHeaderReportForm = async () => {
    let headerId = localStorage.getItem("headerId");
    let response = await expenseReport.getEditHeaderReportForm(
      this.state.companyId,
      headerId
    );
    //added for getting dropdown data in connected_list :sethu
    if (response && response.headerObj) {
      await (getValue(response,"headerObj.headerForm",[])).forEach(async (item) => {
        if (item.connectedListId) {
          let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
          item.page = 1;
          item.connectedListDataCount = getValue(resp, "count", 0);
          item.connectedListData.listManagementData = resp.result;
          item.search = ''
        }
        this.setState({ expenseForm: response.result })
      })
    }
    if (response && headerId) {
      this.setState({
        policyForm: response.headerObj.headerForm,
        selectedPolicy: response.headerObj.policyId.policyName
      });
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.headerObj.headerForm, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== '';
      });
      dynamicFeilds.forEach((fields) => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField ? fields.multiSelectParentField : '',
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.prevFieldId
        });
      })
      this.setState({ connectedListData: newConnectedListDate });
      this.createDropdownForMultiLblConnectedList(newConnectedListDate);
      this.setTheStateOfFormsAndFields(response.headerObj.headerForm);
    }
  };

  setTheStateOfFormsAndFields = (formAndFields) => {
    let formFields = [], fieldsObjects = [];
    formAndFields.forEach(async (formField) => {
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "checkbox") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "search") {
        let response = await expenseReport.getAllLocationsName('');
        let locationNames = [];
        response.result.forEach((list) => {
          locationNames.push({ label: list.name, value: list.id, fieldName: 'search' + fieldName });
        })
        this.setState({ ['search' + fieldName]: locationNames });

        if (formField.isDefaultValue) {
          this.setState({ [fieldName]: formField.defaultValue });
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else if (formField.dataType === "connected_list" || formField.dataType === "different_list") {
        this.setState({ [fieldName]: formField.prevFieldId });
      }
      else {
        this.setState({ [fieldName]: formField.fieldValue });
      }
      formFields.push(formField.fieldName);
      this.setState({ submitButtonStatus: false });
      fieldsObjects.push({ fieldId: formField.id, columnName: formField.columnName ,dataType : formField.dataType});
    });
    this.setState({ isLoading: false });
    this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
  }

  createDropdownForMultiLblConnectedList = (newConnectedListDate) => {
    newConnectedListDate && newConnectedListDate.forEach(async (fields) => {
      if (fields.fieldLevel === 1) {
        let newFieldLevel = fields.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.id && listData.fieldLevel === newFieldLevel);
        if (existConnected.length > 0 && fields.prevFieldId !== undefined && fields.prevFieldId !== '') {
          let companyId = this.state.companyId;
          let response = await expenseReport.getConnectedListData(companyId, fields.prevFieldId);
          let childName = existConnected[0].columnName;
          if (response) {
            this.setState({ [childName]: response });
          }
        }
      } else if (fields.fieldLevel >= 2) {
        let newFieldLevel = fields.fieldLevel + 1;
        let existConnected = newConnectedListDate.filter((listData) => listData.multiSelectParentField === fields.multiSelectParentField && listData.fieldLevel === newFieldLevel);
        if (existConnected.length > 0 && fields.prevFieldId !== undefined && fields.prevFieldId !== '') {
          let companyId = this.state.companyId;
          let response = await expenseReport.getConnectedListData(companyId, fields.prevFieldId);
          let childName = existConnected[0].columnName;
          if (response) {
            this.setState({ [childName]: response });
          }
        }
      }
    });
  }

  onCancelHandler = () => {
    this.props.history.push("./details");
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel,assignKey) => {

    this.state.search[assignKey] = '';
    this.setState({search:this.state.search})
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };
  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter((listData) => listData.multiSelectParentField === conId && listData.fieldLevel === newFieldLevel);
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(companyId, fieldId);
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onChangeHandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };
  onDatePickerHandler = (value, fieldName) => {
    if (!isNaN(value)) {
      this.setState({ [fieldName]: moment(new Date(value)).format("DD MMM YYYY") });
    }
  };
  onCheckboxHandler = fieldName => {
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +(event.target.value) });
    }
  };
  onLocationChangekHandler = (event, fieldName) => {
    this.setState({ [fieldName]: event });
  }

  loadOptions = async (newValue) => {
    return expenseReport.getAllLocationsName(newValue).then((data) => {
      return data.result.map(list => {
        return {
          label: list.name + ',' + list.countryId.name,
          value: list.id
        };
      });
    })
  }

  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key] ? this.state[key].value : "";
          fieldsObjects[index].searchName = this.state[key] ? this.state[key].label : "";
        }
        // else if (fieldsObjects[index].fieldLevel >= 2 && fieldsObjects[index].dataType === "connected_list") {
        //   fieldsObjects[index].fieldValue = this.state[key];
        //   fieldsObjects[index].connectedListData = {};
        //   fieldsObjects[index].connectedListData.listManagementData = this.state[fieldsObjects[index].columnName];
        // }
        else if(fieldsObjects[index].dataType === "connected_list") {

          let response = await expenseReport.getConnectedListDataForSpecific(
            this.state[key]
          );
          fieldsObjects[index].fieldValue = this.state[key];
          if(response && response.itemName){
          fieldsObjects[index].fieldItemName = response.itemName;
          }
        }else{
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }

      let policyForm = [...this.state.policyForm];
      let createExpensePolicy = {
        companyId: this.state.companyId,
        id: localStorage.getItem("headerId"),
        headerForm: fieldsObjects,
        formHeaderId: policyForm[0].formHeaderId
      };
      // console.log(createExpensePolicy)

      let response = await expenseReport.updateHeaderENtryForm(
        createExpensePolicy
      );
      if (response.result && response.result.errorMessages && response.result.errorMessages.errorMessages) {
        this.setState({ submitButtonStatus: false, auditRuleValidationError: response.result.errorMessages.errorMessages, isSubmitClick: false });
      } else {
        this.setState({ isSubmitClick: false });
        this.props.expenseStore.setReportHeader(response);
        this.props.history.push("./details");
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };


  //added for connected list data :sethu

  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already:true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight-25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.policyForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {

    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({search:this.state.search}, () => {
      $(`#valueConnect${assignKey}`).focus();
    })

      let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
      if (resp) {
        setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", resp.result)
      } else {
        setValue(this.state.policyForm[assignKey], "connectedListData.listManagementData", [])
      }
      setValue(this.state.policyForm[assignKey], "search", search)
      this.state.loading[assignKey] = false;
      this.setState({
        ...this.state
      })
      $(`#valueConnect${assignKey}`).focus();
      $(`#valueConnect${assignKey}`).attr('value', search);
      $(`#connect${assignKey}`).addClass("show")


  }

  render() {
    return (
      <div>
        <div className="p-padding">
          <div className="white-card shadow-light pm-30 container">
            <div className="mb-4">
              <h6 className="muted-p text-uppercase mb-0">New</h6>
              <h5 className="modal-title " id="expenseReportLable">
                Update Report Header
              </h5>
            </div>
            <div className="modal-middle">
              {!this.state.isLoading ?
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group custom">
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn btn-border-basic dropdown-toggle btn-block"
                            data-toggle="dropdown"
                            disabled
                          >
                            {this.state.selectedPolicy
                              ? this.state.selectedPolicy
                              : "Policy"}
                          </button>
                        </div>
                      </div>
                      {/**create expense report form dynamically */}
                      <EditExpenseReportPolicy
                        {...this.state}
                        policyForm={this.state.policyForm}
                        validator={this.validator}
                        onChangeHandler={this.onChangeHandler}
                        onClickHandler={this.onClickHandler}
                        onDatePickerHandler={this.onDatePickerHandler}
                        onCheckboxHandler={this.onCheckboxHandler}
                        onIntegerHandler={this.onIntegerHandler}
                        onConnectedListkHandler={this.onConnectedListkHandler}
                        userPermission={this.state.userPermission}
                        onLocationChangekHandler={this.onLocationChangekHandler}
                        loadOptions={this.loadOptions}
                        handleScroll={this.handleScroll}
                        handleSearchManage={this.handleSearchManage}
                      />
                    </div>
                  </div>
                  {
                    this.state.auditRuleValidationError && this.state.auditRuleValidationError.map((validationError, index) =>
                      <p className="text-danger" key={index}>{validationError}</p>)
                  }
                  <div className="modal-last text-left btn-group-margin">
                    <button
                      onClick={this.onCancelHandler}
                      type="button"
                      className="btn lg-padding btn-badge"
                    >
                      Cancel
                </button>
                    {!this.state.isSubmitClick ?
                      <button
                        onClick={this.onSubmitHandler}
                        disabled={this.state.submitButtonStatus}
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        continue
                  </button>
                      :
                      <button
                        type="button"
                        className="btn  "
                        data-dismiss="modal"
                      >
                        Please Wait...
                </button>
                    }
                  </div>
                </>
                :
                <Loader />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditReportHeader;
