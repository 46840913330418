import React, { Component } from "react";
import LeftSidebar from "./components/addExpenseType/leftSidebar";
import { inject, observer } from "mobx-react";
import SimpleReactValidator from "simple-react-validator";
import { expenseReport } from "../../../_services/expenseReport";
import ExpenseCreateForm from "./components/addExpenseType/expenseCreateForm";
import { mileageService } from "../../../_services/mileage.service";
import moment from "moment";
import _ from "lodash";
import Loader from "./../../../_components/common/Loader";
import ColumnNames from "src/_components/common/columnNames";
import { getValue, setValue } from "src/_components/common/lodsh";
import { getConnectedList } from "src/_services";
import $ from "jquery";
import { personalInfoService } from 'src/_services/personalInfoService';

@inject("expenseStore")
@observer
class EditExpenseEntryForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.state = {
      companyId: localStorage["440a28"],
      expenseForm: [],
      error: [],
      formFields: [],
      fieldsObject: [],
      expenseEntryList: [],
      editEntryForm: [],
      auditRuleValidationError: [],
      totoalExpense: 0,
      cashAdvanceTotalAmount: 0,
      totalRequestAmount: 0,
      totalExpenseAmount: 0,
      validationStatus: false,
      userPermission: "employee-role",
      connectedListData: [],
      isLoading: true,
      isSubmitClick: false,
      pageName: "expenseEntry",
      displayTaxFields: false,
      displayCSGST: false,
      displayIGST: false,
      taxAmount: 0,
      cityName: "",
      accomodationType: "",
      userId: "",
      accomodationTypeId: "",
      cpiId: "",
      fieldId: "",
      accomdationList: "",
      // formId:""
      search: [],
      loading: [],
    };
  }
  componentWillUnmount() {
    this.state = {
      search: [],
      loading: []
    }
  }

  componentDidMount() {
    this.getAddExpenseFormField();
    this.getAllCreatedExpenses();
    let cpiId = localStorage.getItem('cpiId');
    this.setState({ cpiId });
    this.getPersonalInfo(cpiId);
  }

  getPersonalInfo = async (cpiId) => {
    this.setState({
      loadingPersonalInfo: true
    })
    let result = await personalInfoService.getPersonalInfo(cpiId);
    if (result) {
      this.setState({ userId: result.userId })
    }
  }

  getAllCreatedExpenses = async () => {
    let headerId = localStorage.getItem("headerId");
    if (headerId) {
      let response = await expenseReport.getAllCreatedExpense(
        this.state.companyId,
        headerId
      );

      if (getValue(response, "expenseReportEntryObj")) {

        let data = getValue(response, "expenseReportEntryObj").filter((item) => { return item.id === localStorage.getItem("expenseEntryId") })
        if (data.length !== 0) {
          let data1 = data[0].entryForm.filter((item) => { return item.dataType === "search" && item.columnName === "LOCATION_NAME" })
          if (data1.length !== 0) {
            this.setState({ cityName: getValue(data1[0], "searchName").split(",")[0] })
          }
        }
      }
      if (response) {
        if (response.expenseReportEntryObj && response.expenseReportEntryObj.length !== 0) {
          let array = response.expenseReportEntryObj
          for (let i = 0; i < array.length; i++) {
            response.expenseReportEntryObj[i].entryForm.forEach((data) => {
              if (data.fieldName === "History" && data.fieldValue) {
                if (data.fieldRolePermissions.length !== 0) {
                  if ((data.fieldRolePermissions[0].permissionId !== "0" ||
                    data.fieldRolePermissions[1].permissionId !== "0") ||
                    (data.fieldRolePermissions[2].permissionId !== "0")) {
                    setValue(response.expenseReportEntryObj[i], "history", "true")
                  }
                } else {
                  setValue(response.expenseReportEntryObj[i], "history", "true")
                }
              }
            })
          }

        } else {
          console.log("error")
        }
      } else {
        console.log("there is no proper data")
      }

      if (response) {
        this.setState({
          reportHeader: response.headerObj.headerForm,
          expenseEntryList: response.expenseReportEntryObj,
          totoalExpense: response.expenseReportEntryObj.length,
          totalExpenseAmount: response.totalAmount,
          userBaseCurrency: response.baseCurrency,
          cashAdvanceTotalAmount: response.cashAdvanceTotalAmount,
          totalRequestAmount: response.requestAmount
        });
        let validationStatus = true;
        response.expenseReportEntryObj &&
          response.expenseReportEntryObj.forEach((list, index) => {
            validationStatus = !list.validation && validationStatus;
          });
        this.setState({ validationStatus: validationStatus });
      }
    }
  };

  /**edit expense entry form on basis of expeseEntryId*/
  getAddExpenseFormField = async () => {

    let expenseEntryId = localStorage.getItem("expenseEntryId");
    let response = await expenseReport.editExpenseEntryForm(expenseEntryId);

    response.result.entryForm.forEach(async item => {
      if (item.connectedListId) {
        let resp = await getConnectedList(this.state.companyId, '', item.connectedListId, 1, 10)
        item.page = 1;
        item.connectedListDataCount = getValue(resp, "count", 0);
        item.connectedListData.listManagementData = resp.result;
        item.search = ''
        if (item.columnName === "LOCATION_NAME") {
          this.setState({ cityName: item.fieldValue })
        }
        else if (item.columnName === "ACCOMMODATION_TYPE") {
          this.setState({ accomdationList: resp.result })
          this.setState({ accomodationType: item.fieldValue })
          this.setState({ conId: item.id })
          resp.result.filter((data) => {
            if (data.itemName === item.fieldValue) {
              this.setState({ accomodationTypeId: data.id })
            }
          })
        }
      }
      this.setState({ expense: response.result })
    })

    if (response && response.result && response.result) {
      let newConnectedListDate = [];
      let dynamicFeilds = [];
      dynamicFeilds = _.filter(response.result.entryForm, function (result) {
        return result.fieldLevel !== undefined && result.fieldLevel !== "";
      });
      dynamicFeilds.forEach(fields => {
        newConnectedListDate.push({
          columnName: fields.columnName,
          id: fields.id,
          multiSelectParentField: fields.multiSelectParentField
            ? fields.multiSelectParentField
            : "",
          fieldName: fields.fieldName,
          fieldLevel: fields.fieldLevel,
          prevFieldId: fields.prevFieldId
        });
      });
      this.setState({
        connectedListData: newConnectedListDate,
        editEntryForm: response.result.entryForm
      });
      this.createDropdownForMultiLblConnectedList(
        newConnectedListDate,
        response.result.entryForm
      );
      this.setTheStateOfFormsAndFields(response.result.entryForm);
    }
  };

  setTheStateOfFormsAndFields = formAndFields => {
    let formFields = [];
    let fieldsObjects = [];
    formAndFields.forEach(async formField => {
      let fieldName = formField.fieldName;
      if (formField.dataType === "date") {
        let todayDate = formField.fieldValue;
        this.setState({ [fieldName]: todayDate });
      } else if (formField.dataType === "checkbox") {
        this.setState({ [fieldName]: formField.fieldValue });
      } else if (formField.dataType === "search") {
        if (
          formField.prevFieldId !== undefined &&
          formField.prevFieldId !== ""
        ) {
          await this.setState({
            [fieldName]: {
              label: formField.fieldValue,
              value: formField.prevFieldId
            }
          });
          this.checkGstForCities(formField.prevFieldId);
        } else {
          this.setState({ [fieldName]: "" });
        }
      } else {
        if (
          formField.prevFieldId !== undefined &&
          formField.prevFieldId !== ""
        ) {
          this.setState({ [fieldName]: formField.prevFieldId });
        } else {
          this.setState({ [fieldName]: formField.fieldValue });
        }
        this.enableCgstTOrIgstField(
          formField.fieldName,
          formField.prevFieldId,
          formAndFields
        );
      }
      formFields.push(formField.fieldName);
      fieldsObjects.push(formField);
    });
    this.setState({ isLoading: false });
    this.setState({ formFields: formFields, fieldsObject: fieldsObjects });
  };

  createDropdownForMultiLblConnectedList = (
    newConnectedListDate,
    entryForm
  ) => {
    newConnectedListDate &&
      newConnectedListDate.forEach(async fields => {
        if (fields.fieldLevel === 1) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField === fields.id &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        } else if (fields.fieldLevel >= 2) {
          let newFieldLevel = fields.fieldLevel + 1;
          let existConnected = newConnectedListDate.filter(
            listData =>
              listData.multiSelectParentField ===
              fields.multiSelectParentField &&
              listData.fieldLevel === newFieldLevel
          );
          if (
            existConnected.length > 0 &&
            fields.prevFieldId !== undefined &&
            fields.prevFieldId !== ""
          ) {
            let companyId = this.state.companyId;
            let response = await expenseReport.getConnectedListData(
              companyId,
              fields.prevFieldId
            );
            let childName = existConnected[0].columnName;
            if (response) {
              this.setState({ [childName]: response });
            }
          }
        }
      });
  };

  onCancelClickHandler = () => {
    let prevPage = sessionStorage.getItem("from");
    let headerId = this.props.match.params.id;
    if (headerId && prevPage !== "finance" && prevPage !== "Expense")
      this.props.history.push(`../details/approval/${headerId}`);
    else if (prevPage === "finance")
      this.props.history.push(`../../expense/details/fincapproval/${headerId}`);
    else if (headerId && prevPage === "Expense")
      this.props.history.push(`../details/${headerId}`);
    else this.props.history.push(`./details`);
  };

  onClickHandler = async (fieldName, conId, fieldId, fieldLevel, assignKey) => {
    sessionStorage.setItem("formSubmission", "pending")
    // this.enableCgstTOrIgstField(fieldName, fieldId);
    // this.setState({ [fieldName]: fieldId });
    // let newFieldLevel = fieldLevel + 1;
    // let connectedListData = [...this.state.connectedListData];
    // let existConnected = connectedListData.filter(
    //   listData =>
    //     listData.multiSelectParentField === conId &&
    //     listData.fieldLevel === newFieldLevel
    // );
    // if (existConnected.length > 0) {
    //   let companyId = this.state.companyId;
    //   let response = await expenseReport.getConnectedListData(
    //     companyId,
    //     fieldId
    //   );
    //   let childName = existConnected[0].columnName;
    //   if (response) {
    //     this.setState({ [childName]: response });
    //   }
    // }

    this.state.search[assignKey] = '';
    this.setState({ search: this.state.search })

    this.setState({ fieldId: fieldId, conId: conId })

    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }

    let companyId = this.state.companyId;
    let fieldsObject = [...this.state.fieldsObject];
    let perdiemTypeObj = fieldsObject.find(field => field.id === conId);
    if (localStorage.getItem("expenseUniqueCode") === "TRV") {
      if (perdiemTypeObj.columnName === ColumnNames.ACCOMMODATION_TYPE) {
        let currencyField = fieldsObject.find(
          field => field.columnName === ColumnNames.CURRENCY
        );

        let amountField = fieldsObject.find(
          field => field.columnName === ColumnNames.AMOUNT
        );

        if (this.state.cityName !== null && this.state.cityName !== undefined && this.state.accomodationTypeId !== null && this.state.accomodationTypeId !== undefined) {
          let response = await expenseReport.getPerdiem({
            city: this.state.cityName,
            accomodationType: fieldId,
            userId: this.state.userId,
            companyId: companyId
          });
          this.setState({
            accomodationTypeId: fieldId,
            [amountField.fieldName]: response.amount,
            [currencyField.fieldName]: response.currency
          });
        }
      }
      else if (perdiemTypeObj.columnName === ColumnNames.CURRENCY) {
        let amountField = fieldsObject.find(
          field => field.columnName === ColumnNames.AMOUNT
        );
        if (this.state.cityName !== null && this.state.cityName !== undefined && this.state.accomodationTypeId !== null && this.state.accomodationTypeId !== undefined) {
          let response = await expenseReport.getPerdiem({
            city: this.state.cityName,
            accomodationType: this.state.accomodationTypeId,
            userId: this.state.userId,
            companyId: companyId,
            currency: fieldId
          });
          this.setState({
            [amountField.fieldName]: response.amount
          });
        }
      }
    }
    else {
      this.enableCgstTOrIgstField(fieldName, fieldId);
      this.setState({ [fieldName]: fieldId });
      let newFieldLevel = fieldLevel + 1;
      let connectedListData = [...this.state.connectedListData];
      let existConnected = connectedListData.filter(
        listData =>
          listData.multiSelectParentField === conId &&
          listData.fieldLevel === newFieldLevel
      );
      if (existConnected.length > 0) {
        let companyId = this.state.companyId;
        let response = await expenseReport.getConnectedListData(
          companyId,
          fieldId
        );
        let childName = existConnected[0].columnName;
        if (response) {
          this.setState({ [childName]: response });
        }
      }
    };
  };

  onConnectedListkHandler = async (fieldName, conId, fieldId, fieldLevel) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.showCgstIgstExchangeAmountRate(fieldName, fieldId);
    this.setState({ [fieldName]: fieldId });
    let newFieldLevel = fieldLevel + 1;
    let connectedListData = [...this.state.connectedListData];
    let existConnected = connectedListData.filter(
      listData =>
        listData.multiSelectParentField === conId &&
        listData.fieldLevel === newFieldLevel
    );
    if (existConnected.length > 0) {
      let companyId = this.state.companyId;
      let response = await expenseReport.getConnectedListData(
        companyId,
        fieldId
      );
      let childName = existConnected[0].columnName;
      if (response) {
        this.setState({ [childName]: response });
      }
    }
  };

  onChangeHandler = (event, key) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [key]: event.target.value.replace(/\|/g, ',') });
  };

  onDatePickerHandler = (value, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    if (!isNaN(value)) {
      this.setState({
        [fieldName]: moment(new Date(value)).format("DD MMM YYYY")
      });
    }
  };

  onCheckboxHandler = fieldName => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ [fieldName]: !this.state[fieldName] });
  };

  onIntegerHandler = (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    if (!isNaN(event.target.value)) {
      this.setState({ [fieldName]: +event.target.value });
      this.showCgstIgstExchangeAmountRate(fieldName);
    }
  };

  //tax calculation start here
  enableCgstTOrIgstField = (fieldName, fieldId, entryForm = []) => {
    let fieldsObject = [...this.state.fieldsObject];
    fieldsObject = fieldsObject.length ? fieldsObject : entryForm;
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (fields && fields.columnName === ColumnNames.TAX_TYPES) {
      let selectedTaxType = fields.connectedListData.listManagementData.find(
        listData => listData.id === fieldId
      );
      if (selectedTaxType && selectedTaxType.itemName === ColumnNames.IGST) {
        this.setState({ displayCSGST: false, displayIGST: true });
      } else if (
        selectedTaxType &&
        selectedTaxType.itemName === "CGST & SGST/UGST"
      ) {
        this.setState({ displayCSGST: true, displayIGST: false });
      }
    }
  };

  showCgstIgstExchangeAmountRate = _.debounce((fieldName, fieldId) => {
    let fieldsObject = [...this.state.fieldsObject];
    let fields = fieldsObject.find(field => field.fieldName === fieldName);
    if (
      fields &&
      fields.columnName === ColumnNames.TAX_RATE &&
      this.state.displayTaxFields
    ) {
      let taxRate = this.state[ColumnNames.TAX_RATE];
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      const amount = this.state[amountFields.fieldName];
      let rate = taxRate.find(rate => rate.id === fieldId);
      this.setPercentageOfCgstIgst(
        rate ? rate.itemName : 0,
        amount ? amount : 0
      );
    } else if (
      fields &&
      fields.columnName === ColumnNames.AMOUNT &&
      this.state.displayTaxFields
    ) {
      let fields = fieldsObject.find(
        field => field.columnName === ColumnNames.TAX_RATE
      );
      let amountFields = fieldsObject.find(
        field => field.columnName === ColumnNames.AMOUNT
      );
      let taxRate = this.state[ColumnNames.TAX_RATE];
      if (taxRate) {
        const amount = this.state[amountFields.fieldName];
        const taxFieldId = this.state[fields.fieldName];
        let rate =
          taxRate &&
          taxRate.length &&
          taxRate.find(rate => rate.id === taxFieldId);
        this.setPercentageOfCgstIgst(
          rate ? rate.itemName : 0,
          amount ? amount : 0
        );
      }
    }
  }, 250);

  setPercentageOfCgstIgst = async (rate, amount) => {
    if (rate > 0 && amount > 0) {
      let response = await mileageService.getTotalTaxPercentage(rate, amount);
      if (response) {
        let fieldsObject = [...this.state.fieldsObject];
        const igst = fieldsObject.find(
          field => field.columnName === ColumnNames.IGST
        );
        const cgst = fieldsObject.find(
          field => field.columnName === ColumnNames.CGST
        );
        const sgst = fieldsObject.find(
          field => field.columnName === ColumnNames.SGST_UTGST
        );
        if (this.state.displayCSGST) {
          this.setState({
            [cgst.fieldName]: response / 2,
            [sgst.fieldName]: response / 2,
            [igst.fieldName]: 0,
            taxAmount: response
          });
        } else {
          this.setState({
            [igst.fieldName]: response,
            [cgst.fieldName]: 0,
            [sgst.fieldName]: 0,
            taxAmount: response
          });
        }
      }
    }
  };

  checkGstForCities = async cityId => {
    let response = await mileageService.getGstAvailable(cityId);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
  };
  //tax calculation end here

  onLocationChangekHandler = async (event, fieldName) => {
    sessionStorage.setItem("formSubmission", "pending")
    this.setState({ cityName: event.label.split(",")[0] })
    let response = await mileageService.getGstAvailable(event.value);
    if (response) {
      this.setState({ displayTaxFields: true });
    } else {
      this.setState({
        displayCSGST: false,
        displayIGST: false,
        displayTaxFields: false
      });
    }
    this.setState({ [fieldName]: event });
    if (localStorage.getItem("expenseUniqueCode") === "TRV") {
      let companyId = this.state.companyId;
      let fieldsObject = [...this.state.fieldsObject];
      let perdiemTypeObj = fieldsObject.find(field => field.id === this.state.conId);
      if (perdiemTypeObj.columnName === ColumnNames.ACCOMMODATION_TYPE) {
        let currencyField = fieldsObject.find(
          field => field.columnName === ColumnNames.CURRENCY
        );
        let amountField = fieldsObject.find(
          field => field.columnName === ColumnNames.AMOUNT
        );

        if (this.state.cityName !== null && this.state.cityName !== undefined && this.state.accomodationTypeId !== null && this.state.accomodationTypeId !== undefined) {
          let response = await expenseReport.getPerdiem({
            city: this.state.cityName,
            accomodationType: this.state.accomodationTypeId,
            userId: this.state.userId,
            companyId: companyId
          });
          this.setState({
            accomodationTypeId: this.state.accomodationTypeId,
            [amountField.fieldName]: response.amount,
            [currencyField.fieldName]: response.currency
          });
        }
      }

      else if (perdiemTypeObj.columnName === ColumnNames.CURRENCY) {
        let amountField = fieldsObject.find(
          field => field.columnName === ColumnNames.AMOUNT
        );
        if (this.state.cityName !== null && this.state.cityName !== undefined && this.state.accomodationTypeId !== null && this.state.accomodationTypeId !== undefined) {
          let response = await expenseReport.getPerdiem({
            city: this.state.cityName,
            accomodationType: this.state.accomodationTypeId,
            userId: this.state.userId,
            companyId: this.state.companyId,
            currency: this.state.fieldId
          });
          this.setState({
            [amountField.fieldName]: response.amount
          });
        }
      }
    };

  }

  loadOptions = async newValue => {
    return expenseReport.getAllLocationsName(newValue).then(data => {
      return data.result.map(list => {
        return {
          label: list.name + "," + list.countryId.name,
          value: list.id
        };
      });
    });
  };

  onSubmitHandler = async () => {
    if (this.validator.allValid()) {
      this.setState({ isSubmitClick: true });
      let fieldsObjects = this.state.fieldsObject;
      let index = 0;
      for (const key of this.state.formFields) {
        if (fieldsObjects[index].columnName === "LOCATION_NAME") {
          fieldsObjects[index].fieldValue = this.state[key]
            ? this.state[key].value
            : "";
          fieldsObjects[index].searchName = this.state[key]
            ? this.state[key].label
            : "";
        } else if (
          fieldsObjects[index].fieldLevel >= 2 &&
          fieldsObjects[index].dataType === "connected_list"
        ) {
          fieldsObjects[index].fieldValue = this.state[key];
          fieldsObjects[index].connectedListData = {};
          fieldsObjects[
            index
          ].connectedListData.listManagementData = this.state[
            fieldsObjects[index].columnName
            ];
        } else {
          fieldsObjects[index].fieldValue = this.state[key];
        }
        index++;
      }
      // fieldsObjects.forEach(async (item) => {
      //   if (item.dataType === "connected_list") {
      //     if (item.fieldValue && getValue(item, "connectedListData.listManagementData", [])) {
      //       let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
      //       setValue(item, "connectedListData.listManagementData", data)
      //     }
      //   }
      // })
      for (var item of fieldsObjects) {
        if (item.dataType === "connected_list") {
          if (item.fieldValue && getValue(item, "connectedListData.listManagementData", [])) {
            let data = getValue(item, "connectedListData.listManagementData", []).filter(resp => { return resp.id === item.fieldValue });
            if (data.length === 0) {
              if((item.prevFieldId && item.isCopyDown === true || item.isDefaultValue === true)){
              let responseMult = await expenseReport.getConnectedListDataForSpecific(
                item.prevFieldId
              )
              if (typeof (responseMult) === 'object') {
                setValue(item, "connectedListData.listManagementData", [responseMult])
              }
              else {
                setValue(item, "connectedListData.listManagementData", responseMult)
              }
              }
            }else {
              setValue(item, "connectedListData.listManagementData", data)
            }
          } else if(item.fieldValue === ""){
            setValue(item, "connectedListData.listManagementData", [])
          }
        }
      }

      let expenseEntryId = localStorage.getItem("expenseEntryId");
      let editEntryForm = [...this.state.editEntryForm];
      let expenseTypeId = localStorage.getItem("expenseType");
      if (expenseTypeId) {
        let expenseTypeId = localStorage.getItem("expenseType");
        let updateEntryForm = {
          id: expenseEntryId,
          companyId: this.state.companyId,
          expenseType: expenseTypeId,
          policyId: localStorage.getItem("policyId"),
          entryForm: fieldsObjects,
          taxAmount: this.state.taxAmount,
          entryFormHeaderId: editEntryForm[0].formHeaderId
        };

        let response = await expenseReport.updateExpenseEntryForm(
          updateEntryForm
        );
        if (
          response.result &&
          response.result.errorMessages &&
          response.result.errorMessages.errorMessages
        ) {
          this.setState({
            submitButtonStatus: false,
            auditRuleValidationError: response.result.errorMessages.errorMessages,
            isSubmitClick: false
          });
        } else {
          // sessionStorage.setItem("formSubmission", "completed")
          this.setState({ isSubmitClick: false });
          let prevPage = sessionStorage.getItem("from");
          let headerId = this.props.match.params.id;
          if (headerId && prevPage !== "finance" && prevPage !== "Expense")
            this.props.history.push(`../details/approval/${headerId}`);
          else if (prevPage === "finance")
            this.props.history.push(
              `../../expense/details/fincapproval/${headerId}`
            );
          else if (headerId && prevPage === "Expense")
            this.props.history.push(`../details/${headerId}`);
          else this.props.history.push(`./details`);
        }
      } else {
        let expenseTypeId = getValue(this.state,"expenseEntryList[0].expenseType")
        let updateEntryForm = {
          id: expenseEntryId,
          companyId: this.state.companyId,
          expenseType: expenseTypeId,
          policyId: localStorage.getItem("policyId"),
          entryForm: fieldsObjects,
          taxAmount: this.state.taxAmount,
          entryFormHeaderId: editEntryForm[0].formHeaderId
        };
        let response = await expenseReport.updateExpenseEntryForm(
          updateEntryForm
        );
        if (
          response.result &&
          response.result.errorMessages &&
          response.result.errorMessages.errorMessages
        ) {
          this.setState({
            submitButtonStatus: false,
            auditRuleValidationError: response.result.errorMessages.errorMessages,
            isSubmitClick: false
          });
        } else {
          sessionStorage.setItem("formSubmission", "completed")
          this.setState({ isSubmitClick: false });
          let prevPage = sessionStorage.getItem("from");
          let headerId = this.props.match.params.id;
          if (headerId && prevPage !== "finance" && prevPage !== "Expense")
            this.props.history.push(`../details/approval/${headerId}`);
          else if (prevPage === "finance")
            this.props.history.push(
              `../../expense/details/fincapproval/${headerId}`
            );
          else if (headerId && prevPage === "Expense")
            this.props.history.push(`../details/${headerId}`);
          else this.props.history.push(`./details`);
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  openAuditTrail = () => { };
  handleScroll = async (e, id, assignKey, page, count, totalLength, search) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    setValue(this.state, "clientHeight", e.target.scrollHeight)
    if (bottom) {
      this.setState({
        already: true
      }, () => {
        $(`#connect${assignKey}`).addClass("show")
        $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
      })
      if (totalLength < count) {
        let resp = await getConnectedList(this.state.companyId, search, id, page + 1, 10)
        if (resp) {
          let mergeOld = getValue(this.state.editEntryForm[assignKey], "connectedListData.listManagementData", []).concat(resp.result)
          setValue(this.state.editEntryForm[assignKey], "connectedListData.listManagementData", mergeOld)
          setValue(this.state.editEntryForm[assignKey], "page", page + 1)
          this.setState({
            ...this.state
          })
          $(`#connect${assignKey}`).addClass("show")
          $(`#connect${assignKey}`).scrollTop(this.state.clientHeight - 25)
        }
      }
    }
  }
  handleSearchManage = async (search, id, assignKey) => {
    this.state.loading[assignKey] = true;
    this.state.search[assignKey] = search;
    this.setState({ search: this.state.search }, () => {
      $(`#valueConnect${assignKey}`).focus();
    })
    let resp = await getConnectedList(this.state.companyId, search, id, 1, 10)
    if (resp) {
      setValue(this.state.editEntryForm[assignKey], "connectedListData.listManagementData", resp.result)
    } else {
      setValue(this.state.editEntryForm[assignKey], "connectedListData.listManagementData", [])
    }
    setValue(this.state.editEntryForm[assignKey], "search", search)
    this.state.loading[assignKey] = false;
    this.setState({
      ...this.state
    })
    $(`#valueConnect${assignKey}`).focus();
    $(`#valueConnect${assignKey}`).attr('value', search);
    $(`#connect${assignKey}`).addClass("show")

  }



  render() {

    return (
      <div>
        <LeftSidebar
          currentPage={this.state.pageName}
          expenseEntryList={this.state.expenseEntryList}
          selectExpenstEntryList={localStorage.getItem("expenseEntryId")}
          totoalExpense={this.state.totoalExpense}
          cashAdvanceTotalAmount={this.state.cashAdvanceTotalAmount}
          userBaseCurrency={this.state.userBaseCurrency}
          totalExpenseAmount={this.state.totalExpenseAmount}
          totalRequestAmount={this.state.totalRequestAmount}
          validationStatus={this.state.validationStatus}
          openAuditTrail={this.openAuditTrail}
        />
        <div className="expense-right  p-padding">
          <div className="modal-content pm-30 border-0">
            <h5 className="modal-title mb-5" id="expenseTypeLabel">
              Update Expense Type
            </h5>
            <div className="modal-middle ">
              {!this.state.isLoading ? (
                <>
                  <div className="row">
                    {/**create add expense form (dynamically */}
                    <ExpenseCreateForm
                      {...this.state}
                      validator={this.validator}
                      policyForm={this.state.editEntryForm}
                      onClickHandler={this.onClickHandler}
                      onChangeHandler={this.onChangeHandler}
                      onDatePickerHandler={this.onDatePickerHandler}
                      onCheckboxHandler={this.onCheckboxHandler}
                      onIntegerHandler={this.onIntegerHandler}
                      userPermission={this.state.userPermission}
                      onConnectedListkHandler={this.onConnectedListkHandler}
                      onLocationChangekHandler={this.onLocationChangekHandler}
                      loadOptions={this.loadOptions}
                      handleScroll={this.handleScroll}
                      handleSearchManage={this.handleSearchManage}
                    />
                  </div>
                  {this.state.auditRuleValidationError &&
                    this.state.auditRuleValidationError.map(
                      (validationError, index) => (
                        <p className="text-danger" key={index}>
                          {validationError}
                        </p>
                      )
                    )}
                  <div className="modal-last text-left btn-group-margin">
                    <button
                      onClick={this.onCancelClickHandler}
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    {!this.state.isSubmitClick ? (
                      <button
                        onClick={this.onSubmitHandler}
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Update
                      </button>
                    ) : (
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                        >
                          Please Wait...
                        </button>
                      )}
                  </div>
                </>
              ) : (
                  <Loader />
                )}
              <div
                className="modal fade"
                id="auditTrailModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="auditTrailLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">VIEW</h6>
                      <h5 className="modal-title" id="auditTrailLabel">
                        Audit Trail
                      </h5>
                    </div>
                    <div className="modal-middle pt-4">
                      <div className="border-bottom py-3 pm-30">
                        <div className="float-left user-name">J</div>
                        <div className="float-left ml-3">
                          <p className="mb-0">
                            <b>Jonathan Cook</b>
                          </p>
                        </div>
                        <div className="float-right">
                          <p className="mb-0">6 min ago</p>
                        </div>
                        <div className="float-left ml-3">
                          <p className="muted-p mb-0">
                            Added Expense Type{" "}
                            <span className="text-primary">
                              Car Rental (Attendee)
                            </span>
                          </p>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="border-bottom py-3 pm-30">
                        <div className="float-left user-name">J</div>
                        <div className="float-left ml-3">
                          <p className="mb-0">
                            <b>Jonathan Cook</b>
                          </p>
                        </div>
                        <div className="float-right">
                          <p className="mb-0">6 min ago</p>
                        </div>
                        <div className="float-left ml-3">
                          <p className="muted-p mb-0">
                            Added Expense Type{" "}
                            <span className="text-primary">
                              Car Business Meal (Attendee)
                            </span>
                          </p>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin pm-30">
                      <button
                        type="button"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="approvalFlowModal_Non"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="approvalFlowLable"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">New</h6>
                      <h5 className="modal-title" id="approvalFlowLable">
                        Approval Flow
                      </h5>
                    </div>
                    <div className="modal-middle pm-30">
                      <div className="form-group">
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider round" />
                        </label>
                        <span>Edit Approvers</span>
                      </div>
                      <div className="form-group custom labelup">
                        <label>Level 1 Approver</label>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Approver"
                        />
                        <span className="icon-custom">
                          <i className="material-icons">search</i>
                        </span>
                      </div>
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Level 2 Manager</label>
                      </div>
                      <div className="form-group custom">
                        <input
                          type="text"
                          placeholder="&nbsp;"
                          className="form-control"
                        />
                        <label>Level 3 manager</label>
                      </div>

                      <div className="modal-last btn-group-margin">
                        <button
                          type="button"
                          className="btn btn-badge"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="button" className="btn  ">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="commentsModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="commentsLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">VIEW</h6>
                      <h5 className="modal-title" id="commentsLabel">
                        Comments
                      </h5>
                    </div>
                    <div className="modal-middle pt-4">
                      <div className="border-bottom py-3 pm-30">
                        <div className="float-left user-name">
                          <img
                            src="/assets/images/booking/user_11.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">
                            <b>Jonathan Cook</b>
                          </p>
                          <div className="float-left">
                            <p className="muted-p mb-0">
                              Hello, Please approve this on priority
                            </p>
                          </div>
                        </div>
                        <div className="float-right">
                          <p className="mb-0">6 min ago</p>
                        </div>

                        <div className="clearfix" />
                      </div>
                      <div className="border-bottom py-3 pm-30">
                        <div className="float-left user-name">
                          <img
                            src="/assets/images/booking/user_11.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">
                            <b>Louisa Boyd</b>
                          </p>
                          <div className="float-left">
                            <p className="muted-p mb-0">Checking!</p>
                          </div>
                        </div>
                        <div className="float-right">
                          <p className="mb-0">6 min ago</p>
                        </div>
                        <div className="clearfix" />
                      </div>
                      <div className="border-bottom py-3 pm-30">
                        <div className="float-left user-name">
                          <img
                            src="/assets/images/booking/user_11.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="float-left ml-3">
                          <p className="mb-0">
                            <b>Doris Clark</b>
                          </p>
                          <div className="float-left">
                            <p className="muted-p mb-0">On it @Jonathan Cook</p>
                          </div>
                        </div>
                        <div className="float-right">
                          <p className="mb-0">6 min ago</p>
                        </div>
                        <div className="clearfix" />
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin pm-30">
                      <button
                        type="button"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="cashAdvAvailableModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="cashAdvAvailableLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">View</h6>
                      <h5 className="modal-title" id="cashAdvAvailableLabel">
                        Cash Advances - Available
                      </h5>
                    </div>
                    <div className="modal-middle mt-5">
                      <div className="table-responsive">
                        <table className="table table-striped border">
                          <thead>
                            <tr>
                              <th className="modal-px-ly">
                                <label className="checkbox-container m-0">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                  <span>CASH ADVANCE</span>
                                </label>
                              </th>
                              <th>DATE ISSUED</th>
                              <th>FOREIGN AMOUNT</th>
                              <th>EXCHANGE RATE</th>
                              <th>AMOUNT</th>
                              <th>BALANCE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="modal-px-ly py-3">
                                <label className="checkbox-container m-0">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                  <span className="text-primary">
                                    Trip to China
                                  </span>
                                </label>
                              </td>
                              <td className="py-3">12/12/18</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">1.00</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">$500.00</td>
                            </tr>
                            <tr>
                              <td className="modal-px-ly py-3">
                                <label className="checkbox-container m-0">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                  <span className="text-primary">
                                    Trip to Canada
                                  </span>
                                </label>
                              </td>
                              <td className="py-3">12/12/18</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">1.00</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">$500.00</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="modal-last btn-group-margin pm-30 ">
                          <button type="button" className="btn  ">
                            ASSIGN CASH ADVANCE TO REPORT
                          </button>
                          <button
                            type="button"
                            className="btn btn-badge"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="cashAdvReportModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="cashAdvReportLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-xl modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">View</h6>
                      <h5 className="modal-title" id="cashAdvReportLabel">
                        Cash Advances Assigned to Report
                      </h5>
                    </div>
                    <div className="modal-middle mt-5">
                      <div className="table-responsive">
                        <table className="table table-striped border">
                          <thead>
                            <tr>
                              <th className="modal-px-ly">
                                <label className="checkbox-container m-0">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                  <span>CASH ADVANCE</span>
                                </label>
                              </th>
                              <th>FOREIGN AMOUNT</th>
                              <th>EXCHANGE RATE</th>
                              <th>AMOUNT</th>
                              <th>BALANCE</th>
                              <th>AMOUNT USED</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="modal-px-ly py-3">
                                <label className="checkbox-container m-0">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                  <span className="text-primary">
                                    Trip to China
                                  </span>
                                </label>
                              </td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">1.00</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">$500.00</td>
                              <td className="py-3">$500.00</td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="modal-last btn-group-margin pm-30 ">
                          <button type="button" className="btn  ">
                            {" "}
                            DELETE FROM REPORT
                          </button>
                          <button
                            type="button"
                            className="btn btn-badge"
                            data-dismiss="modal"
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="importAttDoneModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="importAttDoneLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-head">
                      <h6 className="modal-subtitle">New</h6>
                      <h5 className="modal-title" id="importAttDoneLabel">
                        Import Attendee - Done
                      </h5>
                    </div>
                    <div className="modal-middle">
                      <div className="py-5 check-box text-center">
                        <i className="material-icons">check_circle</i>
                        <h5> 6 Attendees Successfully Imported </h5>
                      </div>
                    </div>
                    <div className="modal-last btn-group-margin pm-30 ">
                      <button
                        type="button"
                        className="btn btn-badge"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="button" className="btn  ">
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditExpenseEntryForm;
