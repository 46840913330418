import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { toast } from "react-toastify";
import { bookingServices } from "src/_services/booking.service";

@inject("authStore")
@inject("commonStore")
@inject("menuStore")
@observer
class LeftMenu extends Component {
  openCloseMenu() {
    var sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("is--extended");
  }
  closeMenu(menu) {
    if (menu.menuName == 'Home') {
      sessionStorage.removeItem('TRIPID');
      sessionStorage.removeItem('cartId');
      sessionStorage.removeItem('req');
    }
    var sidebar = document.getElementById("sidebar");
    sidebar.classList.remove("is--extended");
  }
  clear() {
    localStorage.clear();
    window.location.reload();
  }
  state = {
    isValid: false,
    totalTrip: ''

  }
  loadApprovalCount = false;
  menuList = [];
  componentDidMount() {
    this.props.menuStore.getMenuFromStore();
    this.findNode()
    this.getMyTrips()
  }
  componentDidUpdate() {
    localStorage.setItem('SERVER', false);
    // this.getMyTrips()
  }
  findNode = async () => {
    var menu = toJS(this.props.menuStore.menuItems);
    await menu && menu.forEach(item => {
      if (this.props.menuStore.menuItems) {
        var resp = this.searchTree(item, window.location.pathname);
        if (resp !== null) {
          this.setState({ isValid: true })
          return
        }
      }
    })
    if (!this.state.isValid) {
      // toast.info('You dont have access to view this page.')
      // this.props.history.push('/')
    }
  }
  searchTree = (element, matchingTitle) => {
    if (element.frontEndUrl == matchingTitle) {
      return element;
    } else if (element.children != null) {
      var i;
      var result = null;
      for (i = 0; result == null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], matchingTitle);
      }
      return result;
    }
    return null;
  };

  storeParent = (id, key) => {
    localStorage.setItem("5c6fac", id);
    this.props.menuStore.getParent(id);
    this.props.menuStore.subMenu(id);
    this.currentActive(key);
  };

  getMyTrips = async () => {
    this.loading = true;
    var resp = await bookingServices.overallCount()
    if (resp) {
      this.setState({
        totalTrip: resp
      })
      this.props.menuStore.storeHomeCards(resp);
    }
  }
  activeKey = "";
  currentActive(id) {
    this.activeKey = id;
    this.forceUpdate();
  }

  getApprovalCount = () => {
    if (this.state.totalTrip) {
      let totalApprovalCount = Number(this.state.totalTrip?.trip?.pendingApproval) + Number(this.state.totalTrip?.expense?.pendingApproval) + Number(this.state.totalTrip?.cash?.pendingApproval);
      if (isNaN(totalApprovalCount)) {
        this.loadApprovalCount = true;
        return 0;
      } else {
        // this.getMyTrips()
        this.loadApprovalCount = true;
        return totalApprovalCount > 100 ? 99 : totalApprovalCount
      }
    }

  }
  render() {
    const { menuStore } = this.props;
    return (
      <div id="sidebar" className="sidebar">
        <div className="logo">
          <NavLink to="/">
            <img
              className="logo-img1"
              src="/assets/images/logo_white.svg"
              alt="Curio"
            />
            <img
              className="logo-img"
              src="/assets/images/left-menu-logo.svg"
              alt="Curio"
            />
            <img
              className="hidden"
              src="/assets/images/logo-text-black.png"
              alt=""
            />
          </NavLink>
          <div className="bars" onClick={e => this.openCloseMenu()}>
            <i className="material-icons menu">menu</i>
            {/* <i className="material-icons hidden">close</i> */}
            <span className="hover-name">Menu</span>
          </div>
        </div>
        <div className="navigation">

          <ul>
            {menuStore.menuItems &&
              menuStore.menuItems.map((menu, key) => {
                return (
                  <li onClick={e => this.closeMenu(menu)} key={key}>
                    <NavLink
                      to={menu.frontEndUrl}
                      // title={menu.menuName}
                      className={this.activeKey === key ? "active" : ""}
                      onClick={e => this.storeParent(menu.id, key)}
                    >
                      {menu.menuName === "Dashboard" ? (
                        <div>
                          <i className="material-icons">dashboard</i>
                          <span className="hover-name">Dashboard</span>
                        </div>
                      ) : menu.menuName === "Home" ? (
                        <div>
                          <i className="material-icons">home</i>
                          <span className="hover-name">Home</span>
                        </div>
                      ) : menu.menuName === "My Trips" ? (
                        <div>
                          <i className="material-icons">work</i>
                          <span className="hover-name">My Trips</span>
                        </div>
                      ) : menu.menuName === "Expense Reports" ? (
                        <div>
                          <i className="material-icons">graphic_eq</i>
                          <span className="hover-name">Expense Reports</span>
                        </div>
                      ) : menu.menuName === "Cash Advance" ? (
                        <div>
                          <i className="material-icons">money</i>
                          <span className="hover-name">Cash Advance</span>
                        </div>
                      ) : menu.menuName === "Approvals" ? (
                        <div>

                          {
                            this.getApprovalCount() ? (<span className="approval-count">
                              {this.state.totalTrip && this.getApprovalCount()}
                            </span>) : null
                          }

                          {/* <span className="approval-count">
                            {this.state.totalTrip && this.getApprovalCount() }
                          </span> */}

                          <i className="material-icons">check_circle</i>
                          <span className="hover-name">Approvals</span>
                        </div>
                      ) : menu.menuName === "Cash Admin" ? (
                        <div>
                          <i className="material-icons">nature_people</i>
                          <span className="hover-name">Cash Admin</span>
                        </div>
                      )
                                    : menu.menuName === "Reports" ? (
                                      <div>
                                        <i className="material-icons">assignment</i>
                                        <span className="hover-name">Reports</span>
                                      </div>
                                    )
                                      : menu.menuName === "Administration" ? (
                                        <div>
                                          <i className="material-icons">settings</i>
                                          <span className="hover-name">Administration</span>
                                        </div>
                                      ) : menu.menuName === "Finance Admin" ? (
                                        <div>
                                          <i className="material-icons">assignment_ind</i>
                                          <span className="hover-name">Finance Admin</span>
                                        </div>
                                      ) : (
                                            ""
                                          )}
                      <span className="hidden">{menu.menuName}</span>
                    </NavLink>
                  </li>
                );
              })}
            {/* <li className="border-top"></li> */}
            {/* <li>
              <button title="Logout" className="btn-nothing" onClick={e => this.clear()}>
                <div>
                  <i className="flaticon-power-button-off" />
                  <span className="hidden">Logout</span>
                </div>
              </button>
            </li> */}
            {/* <li className="border-top"></li> */}
          </ul>
        </div>
      </div>
    )
  }
}
export default withRouter(LeftMenu);
