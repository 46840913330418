
import React, { Component, useRef } from 'react';
import NewWindow from 'react-new-window';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import Moment from 'react-moment';
import { getValue } from './common/lodsh';

const ETicketComboPage = (props) => {
  const componentRef = useRef();
  return (
    <NewWindow>
      <div id="root" style={{ backgroundColor: '#fff', padding: "0 15px" }}>
        <ReactToPrint
          trigger={() => <button onClick={e => NewWindow.print()}>Print this out!</button>}
          content={() => componentRef.current}
        />
        {props.eticketInfo.length === 0 &&
          <div style={{ height: '100vh', textAlign: 'center', marginTop: '220px' }}>
            <h5>Your e-ticket is being processed.<br /> Please allow us some time and check again.</h5>
          </div>
        }
        {props.eticketInfo.length !== 0 &&
          <div ref={componentRef}>
            {props.eticketInfo && (props.eticketInfo.bookingType === "FLIGHT") &&
              <div>
                <ul style={{
                  width: '100%',
                  borderBottom: '2px solid rgba(0, 0, 0, .2)',
                  padding: '20px 0px'
                }}>
                  <li style={{
                    listStyle: 'none',
                    display: 'inline-block',
                    width: '33%',
                    textAlign: 'left'
                  }}>
                    <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo.svg" style={{
                      height: '60px',
                      width: 'auto'
                    }} alt="logo" />

                  </li>
                  <li style={{
                    listStyle: 'none',
                    display: 'inline-block',
                    width: '33%',
                    textAlign: 'left'
                  }}>
                    <p style={{
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }}>E-Ticket</p>
                  </li>
                  <li style={{
                    listStyle: 'none',
                    display: 'inline-block',
                    width: '33%',
                    textAlign: 'left'
                  }}>
                    <fieldset style={{
                      border: '1px solid rgba(0, 0, 0, .2)',
                      position: 'relative',
                      width: '100%'
                    }}>
                      <legend style={{
                        position: 'absolute',
                        top: '-16px',
                        fontSize: '14px',
                        fontWeight: 500,
                        left: '10px',
                        backgroundColor: '#fff',
                        width: 'auto',
                        padding: '0px 5px'
                      }}>Your E-Ticket Number</legend>
                      <h5 style={{
                        padding: '15px 15px 10px 15px',
                        fontSize: '14px',
                        fontWeight: 500
                      }}>
                        {props.eticketInfo?.pnrData?.ticketNumber}
                      </h5>
                    </fieldset>
                  </li>
                </ul>
                <div style={{ width: '100%' }}>
                  <table style={{
                    width: '49.7%',
                    display: 'inline-block',
                    verticalAlign: 'text-top'
                  }}>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>Traveller </th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>{getValue(props.eticketInfo, "contact_detail.title", "")}{" "}
                        {getValue(props.eticketInfo, "contact_detail.first_name", "")}{" "}
                        {getValue(props.eticketInfo, "contact_detail.last_name", "")}</td>
                    </tr>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>Airline PNR </th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>{props?.eticketInfo?.pnrData?.pnr}
                      </td>
                    </tr>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>EMAIL</th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>{props.eticketInfo.email}</td>
                    </tr>
                  </table>
                  <table style={{
                    width: '49.7%',
                    display: 'inline-block',
                    verticalAlign: 'text-top'
                  }}>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>Status </th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>{props.eticketInfo.pnrStatus}
                      </td>
                    </tr>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>BOOKING DATE </th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>
                        {/* <Moment format="DD MMM YYYY hh:mm a" > */}
                        {props.eticketInfo.bookingDate}
                        {/* </Moment> */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{
                        padding: '10px',
                        textTransform: 'uppercase',
                        margin: '0px 20px',
                        fontSize: '14px'
                      }}>PNR</th>
                      <td style={{
                        fontWeight: 500,
                        fontSize: '14px'
                      }}>{props?.eticketInfo?.pnrData?.pnr}</td>
                    </tr>
                  </table>
                </div>
              </div>
            }
            <div style={{ marginTop: '30px' }}>
              {/* hotel info starts here */}
              {props.eticketInfo && (props.eticketInfo.bookingType === "HOTEL") && props.eticketInfo.data &&
                <div>
                  <ul style={{
                    width: '100%',
                    //   borderBottom: '2px solid rgba(0, 0, 0, .2)',
                    padding: '20px 0px'
                  }}>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo.svg" style={{
                        height: '60px',
                        width: 'auto'
                      }} alt="logo" />

                    </li>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <p style={{
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }}>E-Voucher</p>
                    </li>
                    <li style={{
                      listStyle: 'none',
                      display: 'inline-block',
                      width: '33%',
                      textAlign: 'left'
                    }}>
                      <fieldset style={{
                        border: '1px solid rgba(0, 0, 0, .2)',
                        position: 'relative',
                        width: '100%'
                      }}>
                        <legend style={{
                          position: 'absolute',
                          top: '-16px',
                          fontSize: '14px',
                          fontWeight: 500,
                          left: '10px',
                          backgroundColor: '#fff',
                          width: 'auto',
                          padding: '0px 5px'
                        }}>E-Voucher Number</legend>
                        <h5 style={{
                          padding: '15px 15px 10px 15px',
                          fontSize: '14px',
                          fontWeight: 500
                        }}>{props.eticketInfo.pnrData.bookingReferenceNumber}</h5>
                      </fieldset>
                    </li>
                  </ul>
                  <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, .2)' }}>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Information </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}></td>
                      </tr>
                    </table>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Traveller Information</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}></td>
                      </tr>
                    </table>
                  </div>
                  <div style={{ width: '100%' }}>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Booking Ref# </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>-</td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Pincode </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.eticketInfo.pnrData.hotelPinCode}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Hotel Phone </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.eticketInfo.pnrData.hotelPhone}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Contact Person</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>
                          {props.eticketInfo.pnrData.contactPerson}</td>
                      </tr>
                    </table>
                    <table style={{
                      width: '49.7%',
                      display: 'inline-block',
                      verticalAlign: 'text-top'
                    }}>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Guest Name </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.eticketInfo.travellerName}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>BOOKING DATE </th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.eticketInfo.bookingDate}
                        </td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Ref #</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>-</td>
                      </tr>
                      <tr>
                        <th style={{
                          padding: '10px',
                          textTransform: 'uppercase',
                          margin: '0px 20px',
                          fontSize: '14px'
                        }}>Status</th>
                        <td style={{
                          fontWeight: 500,
                          fontSize: '14px'
                        }}>{props.eticketInfo.pnrStatus}</td>
                      </tr>
                    </table>
                  </div>
                  <h6 style={{
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>Hotel Information</h6>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <tr>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Hotel</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Check In</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px',
                          color: "green"
                        }}>DURATION</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Check out</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2" style={{
                          padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                        }}>
                          <b style={{ display: "inline-block", marginBottom: "4px" }}>{props.eticketInfo.data.hotel_name}</b><br />
                          {props.eticketInfo.data.hotel_address}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)', opacity: "0.5"
                        }}>
                          {props.eticketInfo.data.hotel_from}
                        </td>
                        <td rowspan="2" style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                        }}>
                          {props.eticketInfo.data.trip_duration}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          fontSize: '14px', border: '1px solid rgb(222, 226, 230)', opacity: "0.5"
                        }}>
                          {props.eticketInfo.data.hotel_to}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Tax Breakup</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Tax <br />
                          {props.eticketInfo.baseCurrency} &nbsp;
                          <NumberFormat value={props.eticketInfo.pnrData.totalTax.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Total Fare <br />
                          {props.eticketInfo.baseCurrency} &nbsp;
                          <NumberFormat value={props.eticketInfo.pnrData.totalFare.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Cancellation Policy</h5>
                  {/* <ul className="list-style" dangerouslySetInnerHTML={{ __html: props.eticketInfo.data.blockDetails.hotel_policies }}></ul> */}
                  <ul className="list-style" dangerouslySetInnerHTML={{ __html: (!props.eticketInfo.data) ? props.eticketInfo.data.blockDetails.hotel_policies : "Not Available" }}></ul>


                </div>

              }
              {/* flight info starts here */}
              {props.eticketInfo && (props.eticketInfo.bookingType === "FLIGHT") && props.eticketInfo.data &&
                <div>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Your Itinerary</h5>
                  <table className="table-bordered" style={{
                    width: '100%'
                  }}>
                    <thead>
                      <tr>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>FLIGHT NUMBER</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Carrier</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Departing</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px',
                          color: "green"
                        }}>DURATION</th>
                        <th style={{
                          textTransform: 'uppercase',
                          fontWeight: 500,
                          padding: '20px 10px',
                          backgroundColor: '#f1f1f2',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>Arrival</th>
                      </tr>
                    </thead>
                    {
                      getValue(props.eticketInfo, "data.fareContent", []).map((item, index) => {
                        return (
                          <tbody>
                            <tr>
                              <td rowspan="2" style={{
                                "fontWeight": "bold", "color": "blue", padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                {item.content[0].flight_number}
                              </td>
                              <td rowspan="2" style={{
                                "fontWeight": "bold", padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                <img src={item.content[0].flight_image} alt="flight-logo" style={{ height: '50px', width: 'auto', textAlign: 'center' }} />
                                <p>{item.content[0].flight_airline}</p>
                              </td>
                              <td style={{
                                padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                {item.content[0].flight_depature_date_utc} &nbsp; - &nbsp;
                              {item.content[0].flight_depature_time}
                              </td>
                              <td rowspan="2" style={{
                                "fontWeight": "800", padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}><span className="text-success">1hr</span><br />
                                {(item.content.length === 1) ?
                                  "Non Stop" : item.content.length - 1 + "Stops"
                                }
                              </td>
                              <td style={{
                                padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                {item.content[0].flight_arrival_date_utc} &nbsp; - &nbsp;
                              {item.content[0].flight_arrival_time}
                              </td>
                            </tr>
                            <tr>
                              <td style={{
                                "fontWeight": "700", padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                {/* {props.eticketInfo.flight_airport[props.eticketInfo.data.flight_from].n}  */}
                          ({item.content[0].flight_from_city}),<br /> IN at Terminal {item.content[0].flight_departure_terminal} </td>
                              <td style={{
                                "fontWeight": "700", padding: '10px',
                                fontSize: '14px', border: '1px solid rgb(222, 226, 230)'
                              }}>
                                {/* {props.eticketInfo.flight_airport[props.eticketInfo.data.flight_to].n} */}
                          ({item.content[0].flight_to_city}),<br /> IN at Terminal {item.content[0].flight_arrival_terminal} </td>
                            </tr>
                          </tbody>
                        )
                      })}
                  </table>

                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Aditional Information</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 20px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Sector</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Meal</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Baggage</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Hand Baggage</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Flight Equipment</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Class</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Seat</th>
                    </thead>
                    {getValue(props.eticketInfo, "data.baggageInfo[0]", []).map((item, index) => {
                      return (
                        < tbody>
                          <tr>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              {item.from}{" - "}{item.to}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              -
                          {/* <NumberFormat value={props.eticketInfo.data.fareContent.fare.flight_original_adult_base_fare && props.eticketInfo.data.fareContent.fare.flight_original_adult_base_fare.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              {item.cab}
                              {/* <NumberFormat value={props.eticketInfo.data.fareContent.fare.flight_total_tax && props.eticketInfo.data.fareContent.fare.flight_total_tax.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              -
                        </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              {/* {props.eticketInfo?.data?.fareContent?.content && props.eticketInfo.data.fareContent.content.map((ele1, key) => {
                            return (key + 1 !== props.eticketInfo.data.fareContent.content.length) ? ele1?.flight_eqiupment + "/" : ele1?.flight_eqiupment
                          })} */}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              {getValue(props.eticketInfo.pnrData.cabintype)}
                              {/* <NumberFormat value={props.eticketInfo.data.fareContent.fare.flight_total_price && props.eticketInfo.data.fareContent.fare.flight_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                            </td>
                            <td style={{
                              "fontWeight": "bold", padding: '10px',
                              border: '1px solid rgb(222, 226, 230)',
                              fontSize: '14px'
                            }}>
                              -
                        </td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>




                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Fare Details</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Info</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Air Fare</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Air Tax & Surcharge</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Total Fare</th>
                    </thead>
                    {/* {props.eticketInfo.data.fareContent.map((item, index) => {
                      return ( */}
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.eticketInfo.pnrData.airLineName} &nbsp;
                      ({props.eticketInfo.pnrData.travellingFrom} -
                      {props.eticketInfo.pnrData.travellingTo})
                      {/* ({item.fare.from} -hkioy=4rj3jre
                      {item.fare.to}) */}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {/* {props.eticketInfo.baseCurrency} &nbsp;
                         <NumberFormat value={item.fare.flight_base_price && Number.parseFloat(item.fare.flight_base_price).toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                          {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props?.eticketInfo?.updatedPrice?.baseFare ? Number.parseFloat(props.eticketInfo.updatedPrice.baseFare).toFixed(2) : ''} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {/* {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={item.fare.flight_total_tax && item.fare.flight_total_tax.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                          {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.eticketInfo?.updatedPrice?.totalTax?.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {/* {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={item.fare.flight_total_price && item.fare.flight_total_price.toFixed(2)} displayType={'text'} thousandSeparator={true} /> */}
                          {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.eticketInfo?.updatedPrice?.totalFare?.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                      </tr>
                    </tbody>
                    {/* )
                    })} */}
                  </table>





                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Fare Charges</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    <thead>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Info</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Cancellation Charges</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Date Change Charges</th>
                      <th style={{
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        padding: '20px 10px',
                        backgroundColor: '#f1f1f2',
                        border: '1px solid rgb(222, 226, 230)',
                        fontSize: '14px'
                      }}>Note</th>
                    </thead>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {props.eticketInfo.pnrData.airLineName} &nbsp;
                      ({props.eticketInfo.pnrData.travellingFrom} -
                      {props.eticketInfo.pnrData.travellingTo})
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>&nbsp; {props.eticketInfo.baseCurrency}&nbsp;
                          {getValue(props.eticketInfo,"data.fareCharges.ADT.BD.C","-")}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>&nbsp; {props.eticketInfo.baseCurrency}&nbsp;
                         {getValue(props.eticketInfo,"data.fareCharges.ADT.BD.A","-")}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          {getValue(props.eticketInfo,"data.fareCharges.note","-")}
                        </td>
                      </tr>
                    </tbody>
                  </table>







                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Tax Airline Breakup</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      {props.eticketInfo?.updatedPrice?.taxBreakup && props.eticketInfo?.updatedPrice?.taxBreakup.map((list, key) => {
                        if (list.fieldValue !== 0) {
                          return (
                            <tr key={key}>
                              <td style={{
                                "fontWeight": "bold", padding: '10px',
                                border: '1px solid rgb(222, 226, 230)',
                                fontSize: '14px', backgroundColor: "#f1f1f2"
                              }}>
                                {list.flieldName}
                              </td>
                              <td style={{
                                "fontWeight": "bold", padding: '10px',
                                border: '1px solid rgb(222, 226, 230)',
                                fontSize: '14px'
                              }}>
                                {props.eticketInfo.baseCurrency}&nbsp;
                             <NumberFormat value={Number.parseFloat(list.fieldValue).toFixed(2)} displayType={'text'} thousandSeparator={true} />
                              </td>
                            </tr>
                          )
                        }
                      })}
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Totals</h5>
                  <table style={{
                    width: '100%'
                  }}>
                    < tbody>
                      <tr>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px', backgroundColor: "#f1f1f2"
                        }}>
                          {props.eticketInfo.updatedPrice.airLine} -
                          {props.eticketInfo.updatedPrice.sector}
                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Base Fare :&nbsp;
                          {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props?.eticketInfo?.updatedPrice?.baseFare ? Number.parseFloat(props.eticketInfo.updatedPrice.baseFare).toFixed(2) : ''} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Tax :&nbsp;
                         {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.eticketInfo?.updatedPrice?.totalTax?.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                        <td style={{
                          "fontWeight": "bold", padding: '10px',
                          border: '1px solid rgb(222, 226, 230)',
                          fontSize: '14px'
                        }}>
                          Total :  {props.eticketInfo.baseCurrency}&nbsp;
                          <NumberFormat value={props.eticketInfo?.updatedPrice?.totalFare?.toFixed(2)} displayType={'text'} thousandSeparator={true} />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}>Rules & Conditions</h5>
                  <ul style={{ paddingLeft: "10px" }}>
                    <li>- Use your airline PNR for all your communication for this  booking with the airline
                    </li>  <li>
                      - All the times indicated are the local time at the relevant airport and we recommend you check-in 2 hours prior to departure
                    </li>  <li>
                      - All the passengers including children and infants, must present valid photo identification at check-in
                    </li>  <li>
                      - Carry a printout of this e-ticket and present it to the airline counter at the time of check-in</li>
                    <li>
                      - Kindly check airline website for baggage information</li>
                  </ul>
                  <h5 style={{ marginTop: '25px', fontWeight: 'bold' }}> Reasons </h5>
                  <p>
                    {(props.eticketInfo?.pnrData?.reason) ?
                      props.eticketInfo?.pnrData?.reason : "-"}
                  </p>
                </div>
              }
            </div>
            <img src="http://dev.curiotechnologies.in/assets/images/left-menu-logo.svg" alt="sign" style={{ height: '50px', width: 'auto', padding: '10px', marginTop: '30px' }} />
            <div style={{ marginTop: '10px' }}>
              <div style={{ float: 'left', padding: '10px' }}>
                <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '6px' }}>For Curio Technologies(P) Ltd.</p>
                {/* <p style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '6px' }}>Govan Travels Prop. Dalmia Bharat s & Ind.</p> */}
                {/* <p style={{ "opacity": "0.5" }}>MM Square, #5M - 671,1st & 2nd Floor, 5th Main,<br /> OMBR Layout,B-Channasandra,
                    Bangalore(KA)-560043</p> */}
                <p style={{ "opacity": "0.5" }}>{getValue(props.eticketInfo, "contact_detail.address", "")}</p>
                {/* </div> */}
                {/* <p style={{ "opacity": "0.5" }}>12th Floor Hansalaya Building 15<br />Barakhama Road, New Delhi - 11001</p> */}
              </div>
              <div style={{ float: 'right', padding: '10px' }}>
                {/* <p style={{ fontSize: '14px', "textAlign": "right", fontWeight: 'bold', marginBottom: '6px' }}>Support: +91 1001191888, 23329432</p>
                <p style={{ "opacity": "0.5" }}>mahesh@nangia.gmail.com</p> */}
                <p style={{ fontSize: '14px', "textAlign": "right", fontWeight: 'bold', marginBottom: '6px' }}>Support: +91-80-41122009</p>
                <p style={{ "opacity": "0.5" }}>info@curiotechnologies.com</p>
              </div>
              <div style={{ "clear": "both" }}></div>
            </div>
          </div>
        }</div>
    </NewWindow>
  )
}
export default ETicketComboPage;

