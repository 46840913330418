import React from "react";
import { NavLink } from "react-router-dom";

const expenseHeader = props => {
  return (
    <ul className="expense-tabs">
      <li className={props.activeNav === "home" ? "active" : ""}>
        <NavLink to="/expense/home">Expense Reports</NavLink>
      </li>
      <li className={props.activeNav === "receipts" ? "active" : ""}>
        <NavLink to="/expense/receipts">Available Receipts</NavLink>
      </li>
      <li className={props.activeNav === "card-transactions" ? "active" : ""}>
        <NavLink to="/expense/card-transactions">Card Transactions</NavLink>
      </li>
    </ul>
  );
};

export default expenseHeader;
